import {
    Component,
    OnInit,

} from '@angular/core';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
import {
    SelectedBuildingService,
    CCUALERTService
} from '@shared/_services';
import { takeUntil, map } from 'rxjs/operators';
import { Building, ProfileUserIntent, UserIntent } from '@/shared/_models';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import {
    ObjectUtil, PucUserIntentTempSliderValueChangedIds,
    PucSliderInputOutputData, PucUserLimitDataSources,
    ZoneSettingsService, SliderUserIntentService, LoaderService,
    HttpCancelService, SiteService, VacationsService,
    HelperService, PubNubService, DeviceHelperService,UnitService,zoneProfile_chilledBeam,
    ConfirmModalComponent, SchedulerService, DateUtil, ArrayUtil,ConfigurationService, NamedScheduleService, AuthenticationService, SchedulerEditModalComponent, zoneProfile_cpu_hyperstat, zoneProfile_hyperstatCpu_specific, zoneProfile_cpu_non_hyperstat,zoneProfile_hyperstatSplit_specific,zoneProfile_vav, scheduleGroupText,
    SchedulerForceTrimModalComponent, dayIndexs, weekDays, weekEnd, weeks,
    FeatureToggleService, zoneProfile_dab
} from '@75f/portal-ui-components';
import { Subject } from 'rxjs/internal/Subject';
import { AnalyticsService } from 'src/app/shared/_services/analytics.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
@Component({
    selector: 'fs-zones-layout',
    templateUrl: './zones-layout.component.html',
    styleUrls: ['./zones-layout.component.scss'],
})
export class ZonesLayoutComponent implements OnInit {
    newSchedulerOnNamedSchedule: any;
    equipObj:any;
    ahuRef:string;
    openedSchedulerEditModal = false;
    showLoadSkeleton:boolean;
    userTempPreference:any;
    showHyperStatCpu:any;
    tempBuildingScheduleInfo: any;
    checkForHyperSense:any;
    showZonesSkeletonLoader :boolean = true;
    showEquipPaired:boolean = false;
    schedulerUnitCheck:any;
    mockBuildings: Array<any> = new Array(10);
    showSchedulerOccupancy: boolean = true;
    schedulerOccupancyLegend = [
		{ name: 'Occupied', color: 'rgba(250, 135, 51, 0.5)', button: false },
		{ name: 'Unoccupied', color: 'rgba(208, 227, 157, 0.5)', button: false },
		{ name: 'edit schedule', color: '', button: true },
	];
    private schedulerEditDialogRef: MatDialogRef<SchedulerEditModalComponent>;
    messagingSubscribe: any;
    userUpdatedValue: boolean = false;
    tempZoneSettingsHolder: any;
    overrideCancelled: boolean;
    isVofm = false;
    isModbus: boolean = false;
    groupingOptions: any = [
        { id: '1', displayName: 'EVERYDAY', class: 'everyday', enum: 'EVERYDAY', value: 3, disabled: false},
        { id: '2', displayName: 'WEEKDAY + WEEKEND', class: 'weekday-weekend', enum: 'WEEKDAY_WEEKEND', value: 2, disabled: false}, 
        { id: '3', displayName: 'WEEKDAY + SATURDAY + SUNDAY', class: 'weekday-sat-sun', enum: 'WEEKDAY_SATURDAY_SUNDAY',  value: 1, disabled: false} , 
        { id: '4', displayName: '7 DAY', class: 'sevendays', enum: '7_DAY', value: 0,  disabled: false }
      ];
    compareCcuVersion: any;
    enableScheduleGroup: boolean = false;
    selectedZoneInfo: any;
    allZonesData = {};
    constructor(
        private siteService: SiteService,
        public helperService: HelperService,
        public loaderService: LoaderService,
        public unitService : UnitService,
        public pubNubService: PubNubService,
        public sliderUserIntentService: SliderUserIntentService,
        public deviceHelper: DeviceHelperService,
        public selectedBuildingService: SelectedBuildingService,
        public vacationsService: VacationsService,
        public ZoneSettingsService: ZoneSettingsService,
        public ccuAlertService: CCUALERTService,
        public httpCancelService: HttpCancelService,
        private dialog: MatDialog,
        public dom: DomSanitizer,
        public schedulerService: SchedulerService,
        public configService: ConfigurationService,
        public namedScheduleService: NamedScheduleService,
        private analyticsService: AnalyticsService,
        private authService: AuthenticationService,
        private featureToggleService: FeatureToggleService
    ) {
        this.subscriptions['popUpCancelClick'] = this.helperService.popUpCancelClick.subscribe(() => {
            this.overrideCancelled = true;
            this.getUserSettingData("");
        })

        this.onChangeDesiredTempSubject.pipe(
            debounceTime(0), distinctUntilChanged())
            .subscribe((val: any) => {
                this.handleSliderChanges()
            });

        this.subscriptions['updateEntitySubscriptions'] = this.pubNubService.updateEntityForNamedSch.pipe(
            debounceTime(500),
            ).subscribe({
            next: () => {
                this.getUserSettingData("");
                this.UpdateBuildingsDataOnNamedSchApply();
            }
        });

        this.subscriptions['flags'] = this.featureToggleService.flagChange.subscribe((flags)=>{
            this.setFlags(flags);
        });
        this.useFeatureToggles();
    }

    public getUserSettingDataSubscriptionCollection: any = {};
    private unsubscribe: Subject<void> = new Subject();
    conflictMsg: string;
    humidifier:boolean;
    hsCpuWidgetsData: Array<any> = [];
    deHumidifier:boolean;
    refs: any = {
        siteRef: undefined,
        floorRef: undefined,
        ccuName: undefined,
        zoneName: undefined,
        equipRef: undefined,
        roomRef: undefined,
        ccuRef: undefined,
    };
    showModbusEmr:boolean = false
    siteRef: string;
    siteTz: string;
    zoneInfo: any;
    zoneName: string;
    equipRef: string;
    roomRef: string;
    ccuRef: string;
    heatingDesriedTempChangeHolder = [];
    coolingDesriedTempChangeHolder = [];
    desiredCoolingTempHolder;
    desiredHeatingTempHolder;
    settingType: any;
    desiredTemp: any
    onChangeDesiredTempSubject = new Subject();
    profiles: any[] = [];
    profileCheckFlag: Boolean = false;
    profileObj: any = {
        profileType: undefined,
        profile: undefined,
    };

    buildings: any = {
        floors: [],
        floorsMaster: [],
        ccus: [],
        floorCcu: {},
        rooms: {},
        equips: [],
        zones: [],
        schedule: [],
    };
    buildingLimit: any = {
        min: undefined,
        max: undefined
    }
    zone: any = {
        equipId: undefined,
        name: undefined,
        priority: undefined,
    }
    tempZones: any = [];
    getPointSubscription: any;
    public zoneVacations: Array<any>;
    sliderdata: any = {
        desiredTempHeating: undefined,
        desiredTempCooling: undefined
    };
    zoneSettings;
    priorityArray: any = [];
    options: any = UserIntent.options;

    buildingMaster: any;
    firstLoad: Boolean = false;
    displayRangeSlider: Boolean = undefined;
    sliderInput: PucSliderInputOutputData = {
        currentTemp: undefined,
        desiredTempHeating: undefined,
        desiredTempCooling: undefined,
        heatingUserLimitMin: undefined,
        heatingUserLimitMax: undefined,
        coolingUserLimitMin: undefined,
        coolingUserLimitMax: undefined,
        coolingDeadband: undefined,
        heatingDeadband: undefined,
    };
    multipleModuleInput: any = {
        heatingUserLimitMin: undefined,
        heatingUserLimitMax: undefined,
        coolingUserLimitMin: undefined,
        coolingUserLimitMax: undefined,
        desiredTempHeating: undefined,
        desiredTempCooling: undefined,
        heatingDeadband: 2,
        coolingDeadband: 2,
    };
    avgCurrentTemp: number = 0;
    coolingDeadbandArray: any = [];
    heatingDeadbandArray: any = [];
    heatUserLimitArray: any = [];
    coolUserLimitArray: any = [];
    multiModuleProfile: boolean = false;
    roomObj: any = [];
    selectedBuilding: Building = {
        siteId: undefined,
        ccus: undefined,
        rooms: undefined,
        siteName:undefined
    };
    selectedbuildingexists: boolean = false;
    multiModuleZoneStatus: any = [];
    zonePriorityVal: any;
    oldZonePriorityVal: any;
    scheduleTypeVal: any;
    oldscheduleTypeVal: any;
    multiModuleZonePriorityList: Map<string, any> = new Map();
    multiModuleScheduleTypeList: Map<string, any> = new Map();
    zonePriorityCount: number = 0;
    zoneScheduleCount: number = 0;
    subscriptions: any = {};
    cancelChanges: any[] = [];
    scheduleGroupLabel: any = scheduleGroupText;
    average = (array) => array.reduce((a, b) => a + b) / array.length;
    schSliderInput: any = {
        currentTemp: undefined,
        desiredTempHeating: undefined,
        desiredTempCooling: undefined,
        heatingUserLimitMin: undefined,
        heatingUserLimitMax: undefined,
        coolingUserLimitMin: undefined,
        coolingUserLimitMax: undefined,
        coolingDeadband: undefined,
        heatingDeadband: undefined,
    }
        ;
    defaultLimits: any = {
        desiredTempHeating: undefined,
        desiredTempCooling: undefined
    };
    //trim sch array holder
    forceTrimArray = [];

    findCurrentTempforMultiModule = (input, equipStatusInput) => {
        let output = undefined;
        let inputValues = input.split(',');
        inputValues.pop();
        let compareConditions = equipStatusInput.split(',');
        compareConditions.pop();
        
        let selectedProfilesCurrentTemp = inputValues.filter((x) => {
            let profiletype = x.split('|')[2];
            let profileId = x.split('|')[3];
            if (new RegExp(/^(vav|dab|cpu|pipe2|pipe4|hpu|sse|ti|dualDuct|series|parallel|vrv|chilledBeam)$/).test(profiletype)) {
                let correspondingEquipStatus = compareConditions.filter((y) => {
                    let equipStatusProfileId = y.split('|')[3];
                    if (profileId == equipStatusProfileId)
                        return y;
                })[0];
                let equipStatus = Number(correspondingEquipStatus?.split('|')[0]);
                if (equipStatus != 3)
                    return x;
            };
        });

        selectedProfilesCurrentTemp.map((x, index) => {
            selectedProfilesCurrentTemp[index] = (!isNaN(parseFloat(x.split('|')[0])) && isFinite(parseFloat(x.split('|')[0]))) ? parseFloat(x.split('|')[0]) : undefined;
        });

       
        selectedProfilesCurrentTemp = selectedProfilesCurrentTemp.filter((x) => x != 0);
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
            let conversion = selectedProfilesCurrentTemp.length == 0 ? '0' : this.average(selectedProfilesCurrentTemp).toFixed(1);
            output = this.unitService.fahrenheitToCelecius(conversion,'currentTemp')
        } else {
            output = selectedProfilesCurrentTemp.length == 0 ? '0' : this.average(selectedProfilesCurrentTemp).toFixed(1);
        }

        return output;

    };

    
    /**
     *  Here it checks for profileType and returns a boolean value.
     */
    checkdisplayprofile() {
        //Multimodule profile cannot be pi and emr
        if (this.multiModuleProfile) {
            if (typeof (this.displayRangeSlider) != undefined) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            if (this.profiles && this.profiles.length == 1) {
                if ((this.profiles[0].profileType == 'pi' || this.profiles[0].profileType == 'emr'  ))
                    return true;
                else {
                    if(this.profiles[0].profileType == 'sense' || this.profiles[0].profileType == 'monitoring') {
                        this.senseProfilecheck()
                    }
                    if (typeof (this.displayRangeSlider) != undefined) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
        }
    }

    /**
 *  Here it checks if temp is Not a Number and returns false else true.
 */
    checkCurrentTempDisplay(temp: any) {
        if (isNaN(temp))
            return false;
        else
            return true;

    }
/**
 *  Here it calls updateZoneSliderInput and sets displayRangeSlider to true.
 */
    senseProfilecheck () {
       this.updateZoneSliderInput()
       this.displayRangeSlider = true;
    }
/**
 * Here it sets displayRangeSlider to a Boolean value based on the properties of zoneSettings.
 */
    validateValuesAndUpdateSlider() {
        if (this.multiModuleProfile) {
            // ends
            let dataReceived = false;
            let dataperprofile = 1;
            if (this.zoneSettings
                && this.zoneSettings?.buildingLimitMin && this.zoneSettings?.buildingLimitMin?.hasOwnProperty('val')
                && this.zoneSettings?.buildingLimitMax && this.zoneSettings?.buildingLimitMax?.hasOwnProperty('val')
                && this.zoneSettings?.desiredTempHeating && this.zoneSettings?.desiredTempHeating?.hasOwnProperty('val')
                && this.zoneSettings?.desiredTempCooling && this.zoneSettings?.desiredTempCooling?.hasOwnProperty('val')
                && this.zoneSettings?.zoneHeatingUserLimitMin && this.zoneSettings?.zoneHeatingUserLimitMin?.hasOwnProperty('val')
                && this.zoneSettings?.zoneHeatingUserLimitMax && this.zoneSettings?.zoneHeatingUserLimitMax?.hasOwnProperty('val')
                && this.zoneSettings?.zoneCoolingUserLimitMin && this.zoneSettings?.zoneCoolingUserLimitMin?.hasOwnProperty('val')
                && this.zoneSettings?.zoneCoolingUserLimitMax && this.zoneSettings?.zoneCoolingUserLimitMax?.hasOwnProperty('val')
            ) {
                if (this.multiModuleZoneStatus.length == this.profiles.length) {
                    this.profiles.forEach(profile => {
                        if (!(this.zoneSettings && this.zoneSettings[profile.profile.name]
                            && this.zoneSettings[profile.profile.name].currentTemp
                            && this.zoneSettings[profile.profile.name].currentTemp.hasOwnProperty('val')
                            && this.zoneSettings[profile.profile.name].heatingDeadband
                            && this.zoneSettings[profile.profile.name].heatingDeadband.hasOwnProperty('val')
                            && this.zoneSettings[profile.profile.name].coolingDeadband
                            && this.zoneSettings[profile.profile.name].coolingDeadband.hasOwnProperty('val')
                        )) {
                            dataperprofile = 0;
                        }
                        dataReceived = (dataperprofile == 0) ? false : true;

                        if (
                            (
                                this.zoneSettings && this.zoneSettings[profile.profile.name]
                                && this.zoneSettings[profile.profile.name].currentTemp
                                && this.zoneSettings[profile.profile.name].heatingDeadband
                                && this.zoneSettings[profile.profile.name].coolingDeadband
                            )
                            &&
                            (
                                this.zoneSettings[profile.profile.name].currentTemp.error
                                || this.zoneSettings[profile.profile.name].heatingDeadband.error
                                || this.zoneSettings[profile.profile.name].coolingDeadband.error
                            )
                        ) {
                            this.displayRangeSlider = false;
                        }
                    });

                    if (dataReceived) {
                        this.calculateAvgCurrentTemp();
                        if (this.zoneSettings['coolingDeadband'] && this.zoneSettings['heatingDeadband']) {
                            this.findHighestDeadbands();
                        }

                        this.updateZoneSliderInput();
                        this.displayRangeSlider = true;
                    }
                }

            }
            else {

                if (
                    (
                        this.zoneSettings
                        && this.zoneSettings?.buildingLimitMin
                        && this.zoneSettings?.buildingLimitMax
                        && this.zoneSettings?.desiredTempHeating
                        && this.zoneSettings?.desiredTempCooling
                        && this.zoneSettings?.zoneHeatingUserLimitMin
                        && this.zoneSettings?.zoneHeatingUserLimitMax
                        && this.zoneSettings?.zoneCoolingUserLimitMin
                        && this.zoneSettings?.zoneCoolingUserLimitMax
                    )
                    &&
                    (
                        this.zoneSettings?.buildingLimitMin?.error
                        || this.zoneSettings?.buildingLimitMax?.error
                        || this.zoneSettings?.desiredTempHeating?.error
                        || this.zoneSettings?.desiredTempCooling?.error
                        || this.zoneSettings?.zoneHeatingUserLimitMin?.error
                        || this.zoneSettings?.zoneHeatingUserLimitMax?.error
                        || this.zoneSettings?.zoneCoolingUserLimitMin?.error
                        || this.zoneSettings?.zoneCoolingUserLimitMax?.error)
                ) {
                    this.displayRangeSlider = false;
                }
            }

        }
        else {
            if (this.zoneSettings
                && this.zoneSettings?.buildingLimitMin && this.zoneSettings?.buildingLimitMin?.hasOwnProperty('val')
                && this.zoneSettings?.buildingLimitMax && this.zoneSettings?.buildingLimitMax?.hasOwnProperty('val')
                && this.zoneSettings?.desiredTempHeating && this.zoneSettings?.desiredTempHeating?.hasOwnProperty('val')
                && this.zoneSettings?.desiredTempCooling && this.zoneSettings?.desiredTempCooling?.hasOwnProperty('val')
                && this.zoneSettings?.currentTemp && this.zoneSettings?.currentTemp?.hasOwnProperty('val')
                && this.zoneSettings?.zoneHeatingUserLimitMin && this.zoneSettings?.zoneHeatingUserLimitMin?.hasOwnProperty('val')
                && this.zoneSettings?.zoneHeatingUserLimitMax && this.zoneSettings?.zoneHeatingUserLimitMax?.hasOwnProperty('val')
                && this.zoneSettings?.zoneCoolingUserLimitMin && this.zoneSettings?.zoneCoolingUserLimitMin?.hasOwnProperty('val')
                && this.zoneSettings?.zoneCoolingUserLimitMax && this.zoneSettings?.zoneCoolingUserLimitMax?.hasOwnProperty('val')
                && this.zoneSettings?.heatingDeadband && this.zoneSettings?.heatingDeadband?.hasOwnProperty('val')
                && this.zoneSettings?.coolingDeadband && this.zoneSettings?.coolingDeadband?.hasOwnProperty('val')
            ) {

                this.updateZoneSliderInput();
                this.displayRangeSlider = true;
            }
            else if (
                (
                    this.zoneSettings
                    && this.zoneSettings?.buildingLimitMin
                    && this.zoneSettings?.buildingLimitMax
                    && this.zoneSettings?.currentTemp
                    && this.zoneSettings?.desiredTempHeating
                    && this.zoneSettings?.desiredTempCooling
                    && this.zoneSettings?.zoneHeatingUserLimitMin
                    && this.zoneSettings?.zoneHeatingUserLimitMax
                    && this.zoneSettings?.zoneCoolingUserLimitMin
                    && this.zoneSettings?.zoneCoolingUserLimitMax
                    && this.zoneSettings?.heatingDeadband
                    && this.zoneSettings?.coolingDeadband
                )
                &&
                (
                    this.zoneSettings?.buildingLimitMin?.error
                    || this.zoneSettings?.buildingLimitMax?.error
                    || this.zoneSettings?.currentTemp?.error
                    || this.zoneSettings?.desiredTempHeating?.error
                    || this.zoneSettings?.desiredTempCooling?.error
                    || this.zoneSettings?.zoneHeatingUserLimitMin?.error
                    || this.zoneSettings?.zoneHeatingUserLimitMax?.error
                    || this.zoneSettings?.zoneCoolingUserLimitMin?.error
                    || this.zoneSettings?.zoneCoolingUserLimitMax?.error
                    || this.zoneSettings?.heatingDeadband?.error
                    || this.zoneSettings?.coolingDeadband?.error
                )
            ) {
                this.displayRangeSlider = false;
            }
        }
    }


    /****Handle Arc** */
    handleSliderChanges() {
        if (this.coolingDesriedTempChangeHolder.length > 0 && this.heatingDesriedTempChangeHolder.length > 0) {
            let heatingChanged = false;
            let coolingChanged = false;
            
            let coolingVal = this.coolingDesriedTempChangeHolder[this.coolingDesriedTempChangeHolder.length - 1];
            this.coolingDesriedTempChangeHolder = [];
            if (coolingVal.temp != this.desiredCoolingTempHolder) coolingChanged = true;

            let heatingVal = this.heatingDesriedTempChangeHolder[this.heatingDesriedTempChangeHolder.length - 1];
            this.heatingDesriedTempChangeHolder = [];
            if (heatingVal.temp != this.desiredHeatingTempHolder) heatingChanged = true;

            if (heatingChanged && coolingChanged) {
                //bothchanged
                this.onChangeDesiredTemp([{
                    value: heatingVal.temp,
                    name: heatingVal.type
                }, {
                    name: coolingVal.type,
                    value: coolingVal.temp
                }])
            } else if (heatingChanged) {
                this.onChangeDesiredTemp([{
                    value: heatingVal.temp,
                    name: heatingVal.type
                    
                }]);
            } else {
                this.onChangeDesiredTemp([{
                    name: coolingVal.type,
                    value: coolingVal.temp
                }]);
            }
        }
        else if (this.coolingDesriedTempChangeHolder.length > 0) {
            let val = this.coolingDesriedTempChangeHolder[this.coolingDesriedTempChangeHolder.length - 1];
            this.coolingDesriedTempChangeHolder = [];
            if (val.temp == this.desiredCoolingTempHolder) return;
            this.onChangeDesiredTemp([{
                value: val.temp,
                name: val.type
                
            }])
        }
        else if (this.heatingDesriedTempChangeHolder.length > 0) {
            let val = this.heatingDesriedTempChangeHolder[this.heatingDesriedTempChangeHolder.length - 1];
            this.heatingDesriedTempChangeHolder = [];
            if (val.temp == this.desiredHeatingTempHolder) return;
            this.onChangeDesiredTemp([{
                value: val.temp,
                name: val.type
                
            }])
        }
    }



    /****** */
    updateZoneSliderInput() {
        /**Temperature Unit prefernce conversions**/
        let sliderValueFromZoneSettings: PucSliderInputOutputData = {
            currentTemp: undefined,
            desiredTempHeating: undefined,
            desiredTempCooling: undefined,
            heatingUserLimitMin: undefined,
            heatingUserLimitMax: undefined,
            coolingUserLimitMin: undefined,
            coolingUserLimitMax: undefined,
            coolingDeadband: undefined,
            heatingDeadband: undefined,
        };
        if(this.profiles[0].profileType == "sense" || this.profiles[0].profileType == "monitoring"){
            this.hyperStatSense(sliderValueFromZoneSettings)
            this.tempConversions()
          
        } else {
            /**Temperature Unit prefernce conversions**/
            this.celciusConversion(sliderValueFromZoneSettings)
        }

        if (this.sliderInput) {
            this.sliderUpdate(sliderValueFromZoneSettings);
        }
        else {
            this.sliderInput = sliderValueFromZoneSettings;
            this.sliderUserIntentService.setData(this.sliderInput, PucUserLimitDataSources.EXTERNAL_DEVICE, this.zoneName);
        }
        this.schSliderInput = Object.assign({}, this.sliderInput);
        this.schSliderInput.desiredTempCooling = this.schSliderInput.coolingUserLimitMin;
        this.defaultLimits.desiredTempCooling = this.schSliderInput.coolingUserLimitMin;
        if(this.schSliderInput?.heatingUserLimitMax > this.schSliderInput?.heatingUserLimitMin) {
            this.schSliderInput.desiredTempHeating = this.schSliderInput?.heatingUserLimitMax;
            this.defaultLimits.desiredTempHeating = this.sliderInput?.heatingUserLimitMax;
        } else {
            this.schSliderInput.desiredTempHeating = this.schSliderInput?.heatingUserLimitMin;
            this.defaultLimits.desiredTempHeating = this.sliderInput?.heatingUserLimitMin;
        }
    }

    hyperStatSense(sliderValueFromZoneSettings) {
        this.checkForHyperSense = true;
        sliderValueFromZoneSettings.currentTemp = this.multiModuleProfile ? this.avgCurrentTemp : this.zoneSettings.currentTemp.val;
        sliderValueFromZoneSettings.desiredTempHeating = '0';
        sliderValueFromZoneSettings.desiredTempCooling = '0';
        sliderValueFromZoneSettings.heatingUserLimitMin = this.zoneSettings.zoneHeatingUserLimitMin.val;
        sliderValueFromZoneSettings.heatingUserLimitMax = this.zoneSettings.zoneHeatingUserLimitMax.val;
        sliderValueFromZoneSettings.coolingUserLimitMin = this.zoneSettings.zoneCoolingUserLimitMin.val;
        sliderValueFromZoneSettings.coolingUserLimitMax = this.zoneSettings.zoneCoolingUserLimitMax.val;
        sliderValueFromZoneSettings.heatingDeadband = '2'
        sliderValueFromZoneSettings.coolingDeadband = '2'
    }
    
    schedulerEventEmit() {
       this.helperService.getPointData()
        .subscribe(res => {
            if(res && this.zoneSettings) {
                if(this.zoneSettings.heatingDeadband)
                    this.zoneSettings.heatingDeadband.val = res.heatingDeadband.val;
                if(this.zoneSettings.coolingDeadband)
                    this.zoneSettings.coolingDeadband.val = res.coolingDeadband.val;
            }
        }) 
        this.schedulerUnitCheck = this.unitService.unitTemperaturePreferenceData()
        this.tempConversions()
    }

    scheduleUnitCheck() {
        if(this.schedulerUnitCheck  == this.unitService.unitTemperaturePreferenceData()){
            this.userTempPreference = this.schedulerUnitCheck 
            return this.userTempPreference;
        } else if(this.schedulerUnitCheck !== this.unitService.unitTemperaturePreferenceData()) {
            this.userTempPreference = this.schedulerUnitCheck 
            return this.userTempPreference
        }
    }

    tempConversions() {
        this.scheduleUnitCheck();
        if(this.zoneSettings.buildingLimitMin && this.zoneSettings.buildingLimitMax) {
            if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) { 
                this.buildingLimit.min =  this.unitService.fahrenheitToCelecius(this.zoneSettings.buildingLimitMin.val,'buildingLimitMin')
                this.buildingLimit.max =  this.unitService.fahrenheitToCelecius(this.zoneSettings.buildingLimitMax.val,'buildingLimitMax')
            } else {
                this.buildingLimit.min = this.zoneSettings.buildingLimitMin.val;
                this.buildingLimit.max = this.zoneSettings.buildingLimitMax.val;
            }
            this.zoneModuleType();
        }
    }

    zoneModuleType() {
        this.zoneInfo.map((zoneInstance) => {
            if (zoneInstance._id == this.roomRef) {
                if (this.multiModuleProfile) {
                    this.checkMultiModuleZoneType(zoneInstance)
                }
                else {
                    if ((zoneInstance.currentTemp !== (this.zoneSettings.currentTemp.val))) {
                      this.checkSingleModuleZoneType(zoneInstance)
                    }
                }
            }
        });
    }

    checkMultiModuleZoneType(zoneInstance) {
        if(!this.allZonesData[zoneInstance._id]) this.allZonesData[zoneInstance._id] = {};
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) { 
           
            this.allZonesData[zoneInstance._id]['currentTemp'] = this.unitService.fahrenheitToCelecius(this.avgCurrentTemp,'currentTemperature');
        } else {
            this.allZonesData[zoneInstance._id]['currentTemp'] = this.avgCurrentTemp;
        }
    }

    checkSingleModuleZoneType(zoneInstance) {
        if(!this.allZonesData[zoneInstance._id]) this.allZonesData[zoneInstance._id] = {};
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) { 
            this.allZonesData[zoneInstance._id]['currentTemp'] = this.unitService.fahrenheitToCelecius(parseInt(this.zoneSettings.currentTemp.val),'currentTemperature') ? this.unitService.fahrenheitToCelecius(parseFloat(this.zoneSettings.currentTemp.val).toFixed(1),'currentTemperature') : 0;
        } else {
            this.allZonesData[zoneInstance._id]['currentTemp'] = parseInt(this.zoneSettings.currentTemp.val) ? parseFloat(this.zoneSettings.currentTemp.val).toFixed(1) : 0;
        }
    }


   
    sliderUpdate(sliderValueFromZoneSettings) {
        if (this.sliderUserIntentService.isEquivalent(this.sliderInput, sliderValueFromZoneSettings)) {
            let dataforuserIntentslider;

            let datalastupdatedby = this.sliderUserIntentService.getLatestUpdatedBy(this.zoneName);
            if (datalastupdatedby)
                dataforuserIntentslider = this.sliderUserIntentService.getData(datalastupdatedby, this.zoneName);
            if (dataforuserIntentslider) {
                if (!this.sliderUserIntentService.isEquivalent(this.sliderInput, dataforuserIntentslider)) {
                    this.sliderUserIntentService.setData(dataforuserIntentslider, PucUserLimitDataSources.EXTERNAL_DEVICE, this.zoneName);
                    this.sliderInput = dataforuserIntentslider;
                }
            }
            else {
                this.sliderUserIntentService.setData(this.sliderInput, PucUserLimitDataSources.EXTERNAL_DEVICE, this.zoneName);
                this.sliderInput = sliderValueFromZoneSettings;
            }
        }
        else {
            this.sliderInput = sliderValueFromZoneSettings;
            this.sliderUserIntentService.setData(this.sliderInput, PucUserLimitDataSources.EXTERNAL_DEVICE, this.zoneName);
        }
    }

    /**Temperature Unit prefernce conversions**/
    celciusConversion(sliderValueFromZoneSettings :any) {
        let userPreferences = this.authService.getLoggedInUserPreferences();
        this.userTempPreference = userPreferences.temperatureUnit;
        this.tempConversions()
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
                sliderValueFromZoneSettings.currentTemp = this.multiModuleProfile ? this.avgCurrentTemp : this.zoneSettings?.currentTemp?.val;
                sliderValueFromZoneSettings.desiredTempHeating = (this.unitService.fahrenheitToCelecius(this.zoneSettings.desiredTempHeating.val, 'desiredTempHeating')).toString()
                sliderValueFromZoneSettings.desiredTempCooling = (this.unitService.fahrenheitToCelecius(this.zoneSettings.desiredTempCooling.val, 'desiredTempCooling')).toString()
                sliderValueFromZoneSettings.heatingUserLimitMin =  (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneHeatingUserLimitMin?.val,'heatingUserLimitMin')).toString()
                sliderValueFromZoneSettings.heatingUserLimitMax =  (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneHeatingUserLimitMax?.val,'heatingUserLimitMax')).toString()
                sliderValueFromZoneSettings.coolingUserLimitMin =  (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneCoolingUserLimitMin?.val,'coolingUserLimitMin')).toString()
                sliderValueFromZoneSettings.coolingUserLimitMax =  (this.unitService.fahrenheitToCelecius(this.zoneSettings?.zoneCoolingUserLimitMax?.val,'coolingUserLimitMax')).toString()
                sliderValueFromZoneSettings.desiredTempHeating =  (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempHeating?.val,'desriedHeating')).toString() 
                sliderValueFromZoneSettings.desiredTempCooling =   (this.unitService.fahrenheitToCelecius(this.zoneSettings?.desiredTempCooling?.val,'desriedCooling')).toString()

                sliderValueFromZoneSettings.heatingDeadband     =  this.unitService.convertingDeadBandValue(this.multiModuleProfile ? this.multipleModuleInput.heatingDeadband : this.zoneSettings?.heatingDeadband?.val) 
                sliderValueFromZoneSettings.coolingDeadband     =  this.unitService.convertingDeadBandValue(this.multiModuleProfile ? this.multipleModuleInput.coolingDeadband : this.zoneSettings?.coolingDeadband?.val);
        } else {
                sliderValueFromZoneSettings.currentTemp = this.multiModuleProfile ? this.avgCurrentTemp : this.zoneSettings?.currentTemp?.val;
                sliderValueFromZoneSettings.desiredTempHeating = this.zoneSettings?.desiredTempHeating?.val;
                sliderValueFromZoneSettings.desiredTempCooling = this.zoneSettings?.desiredTempCooling?.val;
                sliderValueFromZoneSettings.heatingUserLimitMin = this.zoneSettings?.zoneHeatingUserLimitMin?.val;
                sliderValueFromZoneSettings.heatingUserLimitMax = this.zoneSettings?.zoneHeatingUserLimitMax?.val;
                sliderValueFromZoneSettings.coolingUserLimitMin = this.zoneSettings?.zoneCoolingUserLimitMin?.val;
                sliderValueFromZoneSettings.coolingUserLimitMax = this.zoneSettings?.zoneCoolingUserLimitMax?.val;
                sliderValueFromZoneSettings.heatingDeadband = this.multiModuleProfile ? this.multipleModuleInput.heatingDeadband : this.zoneSettings?.heatingDeadband?.val;
                sliderValueFromZoneSettings.coolingDeadband = this.multiModuleProfile ? this.multipleModuleInput.coolingDeadband : this.zoneSettings?.coolingDeadband?.val;
        }   
        this.zoneSettings.heatingDeadband.val = Number(sliderValueFromZoneSettings.heatingDeadband);
        this.zoneSettings.coolingDeadband.val = Number(sliderValueFromZoneSettings.coolingDeadband);   
    }

    stripHayStackTags(dataInput) {
        return this.helperService.stripHaystackTypeMapping(dataInput);
    }

    async ngOnInit() {
		this.isVofm = this.authService.isUserViewOnly();
        let userPreference = this.authService.getLoggedInUserPreferences();
        document.body.classList.add('overflow-hidden');
        this.userTempPreference = userPreference.temperatureUnit;
        this.schedulerUnitCheck = this.userTempPreference;
        this.helperService.clearData();
        //get building details from local storage
        let buildingDetails = JSON.parse(localStorage.getItem('SelectedSite'));
        this.selectedBuilding.siteId = buildingDetails?.siteId;
        this.siteRef = this.helperService.stripHaystackTypeMapping(buildingDetails.siteId);
        this.ccuAlertService.refreshAlertCountEventTrigger();
        this.getZones(this.siteRef);
        this.firstLoad = true;
        this.subscriptions['cancelledChangeSubscriptions'] = this.helperService.cancelledChange.subscribe((param: any) => {
            this.cancelChanges = ArrayUtil.clone(param.list || []);
            this.revertChangedValues(param.list);
        });

        this.subscriptions['vacationAddedSubscriptions'] = this.pubNubService.vacationAdded.pipe(debounceTime(500)).subscribe({
            next: (addedVacId) => {
                this.UpdateBuildingsDataForVacationEdit(addedVacId);
            }
        });
    }

    selectZoneOnLoad() {
        if(this.zoneInfo?.length == 0) {
            this.showZonesSkeletonLoader = false
            this.showEquipPaired = true;
            let loadTime = Date.now() - this.analyticsService.getPageLoadStartTime();
            this.analyticsService.pageLoadTime(loadTime);
        } else if (this.firstLoad && this.zoneInfo[0]) {
            this.selectzone(this.zoneInfo[0]);
            this.firstLoad = false;
        } else {
            this.getZoneData();
        }

    }
/**
 * Here is sets the multiModuleZonePriorityList with name and data.
 */
    multiModuleZonePriority() {
        this.profiles.forEach(profile => {
            if (this.zoneSettings
                && this.zoneSettings[profile.profile.name]
                && this.zoneSettings[profile.profile.name].zonePriority
                && this.zoneSettings[profile.profile.name].zonePriority.hasOwnProperty('val')
                && ['vav', 'dab', 'ti', 'series','otn','bpos','parallel','chilledBeam'].includes(profile['profileType'])
            ) {
                const name = profile.profile.name;
                const data = { 'profile': name, 'profileType': profile.profileType, 'zonePriorityValue': this.zoneSettings[profile.profile.name].zonePriority.val }
                this.multiModuleZonePriorityList.set(name, data);
            }
        })
    }
    multiModuleZoneSchedule() {
        this.profiles.forEach(profile => {
            if (this.zoneSettings
                && this.zoneSettings[profile.profile.name]
                && this.zoneSettings[profile.profile.name].scheduleType
                && this.zoneSettings[profile.profile.name].scheduleType.hasOwnProperty('val')
                && ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti', 'dualDuct', 'series', 'parallel','otn','bpos','vrv','chilledBeam'].includes(profile['profileType'])
            ) {
                const name = profile.profile.name;
                const data = { 'profile': name, 'profileType': profile.profileType, 'scheduleTypeValue': this.zoneSettings[profile.profile.name].scheduleType.val }
                this.multiModuleScheduleTypeList.set(name, data);
            }
        })
    }

    /**
     * Here it checks if zoneScheduleCount and multiModuleScheduleTypeList are of same zize and
     * converts scheduleTypeVal to string.
     */
    getMultiScheduleTypeVal() {
        if (this.profiles.length && this.zoneScheduleCount) {
            if ((this.zoneScheduleCount == this.multiModuleScheduleTypeList.size)) {
                const scheduleTypeValues = Array.from(this.multiModuleScheduleTypeList).map(([key, value]) => value);
                this.scheduleTypeVal = scheduleTypeValues
                    .reduce((max, p) => Number(p.scheduleTypeValue) > max ? Number(p.scheduleTypeValue) : max, Number(scheduleTypeValues[0].scheduleTypeValue)).toString();
            }
        }
    }
    getMultiZonePriorityVal() {
        if (this.profiles.length && this.zonePriorityCount) {
            if ((this.zonePriorityCount == this.multiModuleZonePriorityList.size)) {
                const zonePriorityValues = Array.from(this.multiModuleZonePriorityList).map(([key, value]) => value);
                this.zonePriorityVal = zonePriorityValues
                    .reduce((max, p) => Number(p.zonePriorityValue) > max ? Number(p.zonePriorityValue) : max, Number(zonePriorityValues[0].zonePriorityValue)).toString();
            }
        }
    }
/**
 * Here it calls validateValuesAndUpdateSlider method.
 */

    isDataLoaded(data: any, equipStatus: boolean = false) {
        return data ? this.helperService.isDataLoaded(data, equipStatus) : '';
    }
/**
 * Here it checks for the profile and calls loadProfileTags else sets ' '.
 */
    getProfileData(profileObj: any) {
        profileObj.map(profile => (profile) ? this.loadProfileTags(profile.profileType, profile.profile, profile.tags, profile.modBusEquip) : '');
    }

    loadProfileTags(profileType: string, profileObj: any, tags: any, modBusEquip: string = null) {
        this.profileObj.profileType = profileType;
        this.profileObj.profile = profileObj;
        let zoneProfileType = profileObj.markers.find(e => e === 'hyperstat'  || e === 'hyperstatsplit' );
        let equipName = undefined;
        if (this.multiModuleProfile) {
            equipName = profileObj.name
        }
        switch (profileType) {
            case 'vav':
            case 'series':
            case 'parallel':
                zoneProfile_vav.forEach((_profileObj: any) => {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null,null,_profileObj?.domainName), _profileObj.action, equipName);
                });
            break;

            case 'dab':
                zoneProfile_dab['userIntent'].forEach((_profileObj: any) => {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null,null,_profileObj?.domainName), _profileObj.action, equipName);
                }); 
                break;

            case 'cpu':
                if (zoneProfileType == "hyperstat" || zoneProfileType == "hyperstatsplit") {
                    zoneProfile_cpu_hyperstat.forEach((_profileObj: any) => {
                      this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter,_profileObj?.domainName), _profileObj.action, equipName);
                    });
              
                    if (zoneProfileType == "hyperstat") {
                      zoneProfile_hyperstatCpu_specific.forEach((_profileObj: any) => {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
                        });
                    } else {
                      zoneProfile_hyperstatSplit_specific.forEach((_profileObj: any) => {
                            this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
                        });
                    }
              
                } else {
                    zoneProfile_cpu_non_hyperstat.forEach((_profileObj: any) => {
                      this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj, _profileObj.tags, null, _profileObj.filter), _profileObj.action, equipName);
                    });
                }
                
                break;
                
            
    
            case 'hpu':
                if (zoneProfileType == "hyperstat") {  
                    /**User Intent**/
                    ProfileUserIntent['hyperStatHpu']['userIntent'].forEach((p)=>{
                        this.getZoneSettings(p.name, this.helperService.getPointIdbyTags(profileObj, p.tags, null),p.type,equipName );
                    })
                } else {
                        this.getZoneSettings('targetHumidity', this.helperService.getPointIdbyTags(profileObj, ['target', 'humidity'], null), 'write', equipName);
                        this.getZoneSettings('conditioningMode', this.helperService.getPointIdbyTags(profileObj, ['zone', 'temp', 'mode', 'conditioning'], null), 'write', equipName);
                        this.getZoneSettings('fanMode', this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'mode', 'operation'], null), 'write', equipName);
                        this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write', equipName);
                        this.getZoneSettings('dischargeAirTemp', this.helperService.getPointIdbyTags(profileObj, ['zone', 'discharge', 'air', 'temp', 'sensor'], this.roomRef), 'read', equipName);
                        this.getZoneSettings('relay5Type', this.helperService.getPointIdbyTags(profileObj, ['config', 'relay5', 'group', 'hpu', 'type'], null), 'write', equipName);
                        this.getZoneSettings('targetDehumidity', this.helperService.getPointIdbyTags(profileObj, ['target', 'dehumidifier'], null), 'write', equipName);
                        this.getZoneSettings('compressorstage1', this.helperService.getPointIdbyTags(profileObj, ['zone', 'relay1', 'hpu', 'standalone'], null), 'write', equipName);
                        this.getZoneSettings('compressorstage2', this.helperService.getPointIdbyTags(profileObj, ['zone', 'relay2', 'hpu', 'standalone'], null), 'write',equipName);
                        this.getZoneSettings('fanlowspeed', this.helperService.getPointIdbyTags(profileObj, ['relay3', 'hpu', 'zone', 'standalone','enable'], null), 'write', equipName);

                        this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', equipName);
                        this.getZoneSettings('enablefan', this.helperService.getPointIdbyTags(profileObj, ['zone','hpu','enable','relay5','standalone'], null), 'write',equipName);
                        this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], this.roomRef), 'read', equipName);
                       
                }
                break;

            case 'pipe2':
                if(zoneProfileType == "hyperstat") {

                    ProfileUserIntent['hyperStatPipe2']['userIntent'].forEach((p)=>{
                        this.getZoneSettings(p.name, this.helperService.getPointIdbyTags(profileObj, p.tags, null),p.type,equipName );
                    })

                } else {
               
                    this.getZoneSettings('fanmediumspeed', this.helperService.getPointIdbyTags(profileObj, ['relay1', 'pipe2', 'zone', 'standalone'], null), 'write', equipName);
                    this.getZoneSettings('fanhighspeed', this.helperService.getPointIdbyTags(profileObj, ['relay2', 'pipe2', 'zone', 'standalone'], null), 'write', equipName);
                    this.getZoneSettings('fanlowspeed', this.helperService.getPointIdbyTags(profileObj, ['relay3', 'pipe2', 'zone', 'standalone','enable'], null), 'write', equipName);

                    this.getZoneSettings('conditioningMode', this.helperService.getPointIdbyTags(profileObj, ['zone', 'temp', 'mode', 'conditioning'], null), 'write', equipName);
                    this.getZoneSettings('fanMode', this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'mode', 'operation'], null), 'write', equipName);
                    this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write', equipName);
                    this.getZoneSettings('dischargeAirTemp', this.helperService.getPointIdbyTags(profileObj, ['zone', 'discharge', 'air', 'temp', 'sensor'], this.roomRef), 'read', equipName);

                    this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', equipName);
                    this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], this.roomRef), 'read', equipName);
                }
                break;

            case 'pipe4':
               
                this.getZoneSettings('watervalueheating', this.helperService.getPointIdbyTags(profileObj, ['relay4', 'pipe4', 'zone', 'standalone','enable'], null), 'write', equipName);
                this.getZoneSettings('watervaluecooling', this.helperService.getPointIdbyTags(profileObj, ['relay6', 'pipe4', 'zone', 'standalone','enable'], null), 'write', equipName);
                this.getZoneSettings('fanmediumspeed', this.helperService.getPointIdbyTags(profileObj, ['relay1', 'pipe4', 'zone', 'standalone','enable'], null), 'write', equipName);
                this.getZoneSettings('fanhighspeed', this.helperService.getPointIdbyTags(profileObj, ['relay2', 'pipe4', 'zone', 'standalone','enable'], null), 'write', equipName);
                this.getZoneSettings('fanlowspeed', this.helperService.getPointIdbyTags(profileObj, ['relay3', 'pipe4', 'zone', 'standalone','enable'], null), 'write', equipName);
                this.getZoneSettings('conditioningMode', this.helperService.getPointIdbyTags(profileObj, ['zone', 'temp', 'mode', 'conditioning'], null), 'write', equipName);
                this.getZoneSettings('fanMode', this.helperService.getPointIdbyTags(profileObj, ['zone', 'fan', 'mode', 'operation'], null), 'write', equipName);
                this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write', equipName);
                this.getZoneSettings('dischargeAirTemp', this.helperService.getPointIdbyTags(profileObj, ['zone', 'discharge', 'air', 'temp', 'sensor'], this.roomRef), 'read', equipName);

                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', equipName);
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], this.roomRef), 'read', equipName);

                break;

            case 'emr':
                
                this.getZoneSettings('currentRate', this.helperService.getPointIdbyTags(profileObj, ['emr', 'rate'], null), 'read');
                this.getZoneSettings('energyReading', this.helperService.getPointIdbyTags(profileObj, ['emr', 'sensor', 'sp'], null), 'read');
                this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write');
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], this.roomRef), 'read');
                break;

            case 'pid':
                this.getZoneSettings('loopOutput', this.helperService.getPointIdbyTags(profileObj, ['control', 'variable'], null), 'read');
                this.getZoneSettings('targetValue', this.helperService.getPointIdbyTags(profileObj, ['zone', 'pid', 'target', 'config', 'value'], null), 'read');
                this.getZoneSettings('inputValue', this.helperService.getPointIdbyTags(profileObj, ['process', 'logical', 'variable'], null), 'read');
                this.getZoneSettings('offsetValue', this.helperService.getPointIdbyTags(profileObj, ['setpoint', 'sensor', 'offset'], null), 'read');
                this.getZoneSettings('dynamicValue', this.helperService.getPointIdbyTags(profileObj, ['pid', 'dynamic', 'target', 'zone', 'value'], null), 'read');
                this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write');
                this.getZoneSettings('dynamicSetpointValue', this.helperService.getPointIdbyTags(profileObj, ['analog2', 'config', 'enabled'], null), 'write');
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], this.roomRef), 'read');
                break;

            case 'sse':
                this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write', equipName);
                this.getZoneSettings('dischargeAirTemp', this.helperService.getPointIdbyTags(profileObj, ['zone', 'discharge', 'air', 'temp', 'sensor'], null), 'read', equipName);

                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', equipName);
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], this.roomRef), 'read', equipName);
                break;

            case 'sense':
                this.getZoneSettings('analog1', this.helperService.getPointIdbyTags(profileObj, ['sense', 'logical', 'analog1'], null), 'read', equipName);
                this.getZoneSettings('analog2', this.helperService.getPointIdbyTags(profileObj, ['sense', 'logical', 'analog2'], this.roomRef), 'read', equipName);
                this.getZoneSettings('th1', this.helperService.getPointIdbyTags(profileObj, ['sense', 'logical', 'th1'], null), 'read', equipName);
                this.getZoneSettings('th2', this.helperService.getPointIdbyTags(profileObj, ['sense', 'logical', 'th2'], null), 'read', equipName);
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], this.roomRef), 'read', equipName);
                break;

            case 'monitoring':
                this.getZoneSettings('analog1', this.helperService.getPointIdbyTags(profileObj, ['monitoring', 'logical', 'analog1'], null), 'read', equipName);
                this.getZoneSettings('analog2', this.helperService.getPointIdbyTags(profileObj, ['monitoring', 'logical', 'analog2'], this.roomRef), 'read', equipName);
                this.getZoneSettings('th1', this.helperService.getPointIdbyTags(profileObj, ['monitoring', 'logical', 'th1'], null), 'read', equipName);
                this.getZoneSettings('th2', this.helperService.getPointIdbyTags(profileObj, ['monitoring', 'logical', 'th2'], null), 'read', equipName);
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], this.roomRef), 'read', equipName);
                break;

            case 'vrv':
                
                
                this.getZoneSettings('vrvHumidity', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'zone', 'humidity', 'logical'], null), 'read', equipName);
                this.getZoneSettings('airflowDirectionAutoCapability', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'airflowDirection', 'auto'], null), 'read', equipName);
                this.getZoneSettings('airflowDirectionSupportCapability', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'airflowDirection', 'support'], null), 'read', equipName);
                this.getZoneSettings('fanSpeedAutoCapability', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'fanSpeed', 'auto'], null), 'read', equipName);
                this.getZoneSettings('fanSpeedControlLevelCapability', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'fanSpeed', 'controlLevel'], null), 'read', equipName);
                this.getZoneSettings('operationMode', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'operation', 'mode', 'userIntent'], null), 'write', equipName);
                this.getZoneSettings('fanSpeed', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'fanSpeed', 'userIntent'], null), 'write', equipName);
                this.getZoneSettings('airflowDirection', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'airflowDirection', 'userIntent'], null), 'write', equipName);
                this.getZoneSettings('masterControllerMode', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'masterController'], null), 'write', equipName);

                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', equipName);
                this.getZoneSettings('masterOperationMode', this.helperService.getPointIdbyTags(profileObj, ['vrv', 'master', 'operation'], null), 'read', equipName);
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], this.roomRef), 'read', equipName);
                break;
            
            case 'otn':
               
                this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write', equipName);
                this.getZoneSettings('occupancy', this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null), 'read', equipName);
                this.getZoneSettings('humidity', this.helperService.getPointIdbyTags(profileObj, ['zone', 'humidity', 'otn'], null), 'read', equipName);
                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', equipName);
                this.getZoneSettings('zonePriority', this.helperService.getPointIdbyTags(profileObj, ['zone', 'priority', 'config'], null), 'write', equipName);
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null), 'read', equipName);

                break;
            
            case 'bpos':
               
                this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write', equipName);
                this.getZoneSettings('occupancy', this.helperService.getPointIdbyTags(profileObj, ['zone', 'occupancy', 'mode'], null), 'read', equipName);
                this.getZoneSettings('humidity', this.helperService.getPointIdbyTags(profileObj, ['zone', 'humidity', 'bpos'], null), 'read', equipName);
                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', equipName);
                this.getZoneSettings('zonePriority', this.helperService.getPointIdbyTags(profileObj, ['zone', 'priority', 'config'], null), 'write', equipName);
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null), 'read', equipName);
    
                break;

            case 'ti':
            
                this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write', equipName);
                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', equipName);
                this.getZoneSettings('zonePriority', this.helperService.getPointIdbyTags(profileObj, ['zone', 'priority', 'config'], null), 'write', equipName);
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null), 'read', equipName);

                break;

            case 'dualDuct':
                this.getZoneSettings('equipStatusMessage', this.helperService.getPointIdbyTags(profileObj, ['zone', 'message', 'status'], null), 'write', equipName);
                this.getZoneSettings('dischargeAirTemp', this.helperService.getPointIdbyTags(profileObj, ['zone', 'discharge', 'air', 'temp', 'sensor'], null), 'read', equipName);
                this.getZoneSettings('coolingSupplyAir', this.helperService.getPointIdbyTags(profileObj, ['cooling', 'supply', 'zone', 'point', 'dualDuct', 'logical'], null), 'read', equipName);
                this.getZoneSettings('heatingSupplyAir', this.helperService.getPointIdbyTags(profileObj, ['heating', 'supply', 'zone', 'point', 'dualDuct', 'logical'], null), 'read', equipName);
                this.getZoneSettings('coolingDamper', this.helperService.getPointIdbyTags(profileObj, ['cooling', 'damper', 'zone', 'point', 'dualDuct', 'logical'], null), 'read', equipName);
                this.getZoneSettings('heatingDamper', this.helperService.getPointIdbyTags(profileObj, ['heating', 'damper', 'zone', 'point', 'dualDuct', 'logical'], null), 'read', equipName);

                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', equipName);
                this.getZoneSettings('lastUpdated', this.helperService.getPointIdbyTags(profileObj, ['heartbeat'], null), 'read', equipName);

                break;

            case 'modbus':
                profileObj = this.modbusPoints(profileObj,equipName);
            
            break;

            case 'chilledBeam':
                zoneProfile_chilledBeam['userIntent'].forEach((_profileObj: any) => {
                    this.getZoneSettings(_profileObj.key, this.helperService.getPointIdbyTags(profileObj,_profileObj.tags, _profileObj.roomRef,null,_profileObj?.domainName), _profileObj.action, equipName);
                });
                break;

            default:
                console.log('This profile is not handled');
                break;
        }
        if (profileType !== 'modbus') {
            this.getZoneSettings('desiredTempCooling', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'cooling'], null,null,'desiredTempCooling'), 'write');
            this.getZoneSettings('desiredTempHeating', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'heating'], null,null,'desiredTempHeating'), 'write');
            this.getZoneSettings('currentTemp', this.helperService.getPointIdbyTags(profileObj, ['current', 'temp'], null,null,'currentTemp'), 'read',equipName);
            this.getZoneSettings('zoneHeatingUserLimitMin', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['heating', 'user', 'limit', 'min','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneCoolingUserLimitMax', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['cooling', 'user', 'limit', 'max','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneHeatingUserLimitMax', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['heating', 'user', 'limit', 'max','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneCoolingUserLimitMin', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['cooling', 'user', 'limit', 'min','schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('buildingLimitMin', this.helperService.getPointIdbyTags(this.buildings, ['building', 'limit', 'min'],null,['tuner']), 'write');
            this.getZoneSettings('buildingLimitMax', this.helperService.getPointIdbyTags(this.buildings, ['building', 'limit', 'max'],null,['tuner']), 'write');
            this.getZoneSettings('buildingToZoneDifferential', this.helperService.getPointIdbyTags(this.buildings, ['building', 'default', 'differential', 'kind'],null,['tuner']), 'write');
            this.getZoneSettings('heatingDeadband', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['heating', 'deadband', 'base', 'schedulable'], this.refs.roomRef,), 'write');
            this.getZoneSettings('coolingDeadband', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['cooling', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write');
            this.getZoneSettings('zoneLevelunoccupiedZoneSetback', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['zone', 'setback', 'unoccupied', 'kind','schedulable'], this.refs.roomRef), 'write');
            if (this.multiModuleProfile) {
                this.getZoneSettings('heatingDeadband', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['heating', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write', profileObj.name);
                this.getZoneSettings('coolingDeadband', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['cooling', 'deadband', 'base', 'schedulable'], this.refs.roomRef), 'write', profileObj.name);
                this.getZoneSettings('desiredTempCooling', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'cooling'], null), 'write', profileObj.name);
                this.getZoneSettings('desiredTempHeating', this.helperService.getPointIdbyTags(profileObj, ['temp', 'air', 'desired', 'heating'], null), 'write', profileObj.name);
                this.getZoneSettings('zonePriority', this.helperService.getPointIdbyTags(profileObj, ['zone', 'priority', 'config'], null), 'write', profileObj.name);
                this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write', profileObj.name);
            }
            this.getZoneSettings('equipScheduleStatus', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleStatus'], null), 'write');
            this.getZoneSettings('zoneSchedule', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['zone', 'schedule', 'days'], this.refs.roomRef), 'schedule');
            this.getZoneSettings('buildingSchedule', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['building', 'schedule', 'days']) , 'schedule');
            this.getZoneSettings('buildingOccupancy', this.helperService.getPointIdbyTags(this.buildings.buildingOccupancy, ['building', 'occupancy','days']) , 'schedule');
            this.getZoneSettings('zoneVacation', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['schedule', 'range', 'vacation'], this.refs.roomRef), 'vacation');
            this.getZoneSettings('zoneSpecialSchedule', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['schedule', 'range', 'special'], this.refs.roomRef), 'vacation');
            this.getZoneSettings('scheduleType', this.helperService.getPointIdbyTags(profileObj, ['zone', 'scheduleType'], null), 'write');
            this.getZoneSettings('singleDual', this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['hvacMode'], this.refs.roomRef), 'read');
            //revamped scheduler building level user limit points
            this.getZoneSettings('buildingHeatingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings, ['heating', 'user', 'limit', 'max', 'default', 'schedulable'],null,null,'heatingUserLimitMax'), 'write');
            this.getZoneSettings('buildingCoolingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings, ['cooling', 'user', 'limit', 'min', 'default', 'schedulable'],null,null,'coolingUserLimitMin'), 'write');
            this.getZoneSettings('buildingHeatingUserLimitMin', this.helperService.getPointIdbyTags(this.buildings, ['heating', 'user', 'limit', 'min', 'default', 'schedulable'],null,null,'heatingUserLimitMin'), 'write');
            this.getZoneSettings('buildingCoolingUserLimitMax', this.helperService.getPointIdbyTags(this.buildings, ['cooling', 'user', 'limit', 'max', 'default', 'schedulable'],null,null,'coolingUserLimitMax'), 'write');
            this.getZoneSettings('buildingHeatingDeadband', this.helperService.getPointIdbyTags(this.buildings, ['heating', 'deadband', 'base', 'default', 'schedulable'],null,null,'heatingDeadband'), 'write');
            this.getZoneSettings('buildingCoolingDeadband', this.helperService.getPointIdbyTags(this.buildings, ['cooling', 'deadband', 'base', 'default', 'schedulable'],null,null,'coolingDeadband'), 'write');
            this.getZoneSettings('buildingUnoccupiedZoneSetback', this.helperService.getPointIdbyTags(this.buildings, ['unoccupied', 'setback', 'default', 'schedulable'],null,null,'unoccupiedZoneSetback'), 'write');
            
        }
    }

    modbusPoints(profileObj,equipName) {
        let isHeartBeat = false;
        let points = [];
        profileObj['noPoints'] = true;
        points = this.helperService.getPointIdbyTags(profileObj, ["modbus", "shortDis", "displayInUi"], null) || [];
        let heartBeatPoints = this.helperService.getPointIdbyTags(profileObj, ["heartbeat"], null) || [];
        if(heartBeatPoints.length) isHeartBeat = true;
        points = heartBeatPoints.concat(points);
        points = points.map(point => point);
        if(points.length > 0) {
            if(isHeartBeat)
                profileObj['noPoints'] = points.length < 2;
            else
                profileObj['noPoints'] = false;

            points.forEach((p,i)=>{
                let label = i==0 && isHeartBeat? 'lastUpdated': "modbus" + (i + 1);
                let poinType = this.helperService.pointType(profileObj, points[i], this.refs.roomRef,'writable') ? 'read&hisWrite' : (this.helperService.pointType(profileObj, points[i], this.refs.roomRef,'his')? 'read&hisRead' : 'read&read'); 
                this.getZoneSettings(label, [p], poinType, equipName);
            })
        }
        return profileObj;
    }
/**
 * Here it sets the properties of zoneSettings and calls multiModuleZonePriority,getMultiZonePriorityVal
 */
    handleZonePriority() {
        if (this.multiModuleProfile) {
            this.multiModuleZonePriority();
            this.getMultiZonePriorityVal();
        } else {
            if (this.zoneSettings && this.zoneSettings.zonePriority && this.zoneSettings.zonePriority.hasOwnProperty('val')) {
                this.zonePriorityVal = String(this.zoneSettings.zonePriority.val)
            }
        }
    }
    
    handleZoneSchedule() {
        if (this.multiModuleProfile) {
            this.multiModuleZoneSchedule();
            this.getMultiScheduleTypeVal();
        } else {
            if (this.zoneSettings && this.zoneSettings.scheduleType && this.zoneSettings.scheduleType.hasOwnProperty('val')) {
                this.scheduleTypeVal = String(this.zoneSettings.scheduleType.val)
            }
        }
    }
/**
 * Here it returns the senseData by checking the values of pointId.
 */
getSenseData(profileObj) {
    let senseData = {
        analog1: '',
        analog2: '',
        th1:'',
        th2: '',
    }
    if (profileObj) {
        let th1      = this.helperService.getPointIdbyTags(profileObj, [this.profileObj.profileType, 'logical', 'th1'], null);
        let th2      = this.helperService.getPointIdbyTags(profileObj, [this.profileObj.profileType, 'logical', 'th2'], null);
        let analog1  = this.helperService.getPointIdbyTags(profileObj, [this.profileObj.profileType, 'logical', 'analog1'], null);
        let analog2  = this.helperService.getPointIdbyTags(profileObj, [this.profileObj.profileType, 'logical', 'analog2'], null);
        
        if (profileObj.entities) {
            profileObj.entities.map(entity => {
                [{
                    pointId: analog1,
                    key: 'analog1'
                }, {
                    pointId: analog2,
                    key: 'analog2'
                }, {
                    pointId: th1,
                    key: 'th1'
                }, {
                    pointId: th2,
                    key: 'th2'
                }].forEach((point) => {
                    if (point.pointId == entity._id) {
                        senseData[point.key] = entity.dispName;
                    }
                });
            });
        }
    }
    return senseData;
}


    getPIData(profileObj) {
        let piData = {
            target: '',
            dynamicTarget: '',
            loopOutput:'',
            input: '',
            outputUnit: '',
            inputUnit: ''
            
        }
        if (profileObj) {
            let target = this.helperService.getPointIdbyTags(profileObj, ['zone', 'pid', 'target', 'config', 'value'], null);
            let dynamicTarget = this.helperService.getPointIdbyTags(profileObj, ['pid', 'dynamic', 'target', 'zone', 'value'], null);
            let input = this.helperService.getPointIdbyTags(profileObj, ['process', 'logical', 'variable'], null);
            let loopOutput = this.helperService.getPointIdbyTags(profileObj, ['control', 'variable'], null);
            if (profileObj.entities) {
                profileObj.entities.map(entity => {
                    [{
                        pointId: target,
                        key: 'target'
                    }, {
                        pointId: dynamicTarget,
                        key: 'dynamicTarget'
                    }, {
                        pointId: loopOutput,
                        key: 'loopOutput'
                    },{
                        pointId: input,
                        key: 'input'
                    }].forEach((point) => {
                        if (point.pointId == entity._id) {
                            piData[point.key] = entity.dispName
                            if (point.key == 'input') {
                                piData.inputUnit = entity.unit;
                            } else if(point.key == 'loopOutput') {
                                piData.loopOutput = entity.unit;
                            }else {
                                piData.outputUnit = entity.unit;
                            }
                        }
                    });
                });
            }
        }

        return piData;

    }
/**
 * Here it checks for the value of key. If the key value is not 'vacationAdded' it
 * calls methods:handleHumidityDehumidity,handleZonePriority,handleZoneSchedule,heartBeathandle
 */
    getUserSettingData(key: string) {
        if(this.getUserSettingDataSubscriptionCollection['getPointData'])  this.getUserSettingDataSubscriptionCollection['getPointData'].unsubscribe();
        this.getUserSettingDataSubscriptionCollection['getPointData'] = this.helperService.getPointData()
            .pipe(
                takeUntil(this.unsubscribe))
            .subscribe(res => {
                if (key == "vacationAdded") {
                    this.zoneSettings = ObjectUtil.deepClone(res);
                    this.tempZoneSettingsHolder = ObjectUtil.deepClone(res);
                    if (this.vacationsService.updateVacationList && this.zoneSettings && this.zoneSettings.zoneVacation) {
                        this.zoneVacations = [].concat(this.zoneSettings.zoneVacation)
                    }
                      /**
                       * In the zone-layout page we are deepcloning zonesettings.lastupdated point is retuning value as number  instead of dayjs object
                        so vacation is breaking in zones-layout page  so we are calling  heartBeathandle() function*
                     **/
                    this.heartBeathandle();
                    this.setSpecialSchedule();
                    if(this.getUserSettingDataSubscriptionCollection[key]){
                    this.getUserSettingDataSubscriptionCollection[key].unsubscribe();
                  }
                    delete this.getUserSettingDataSubscriptionCollection[key];
                }
                else {
                    const response = ObjectUtil.deepClone(res);
                    const zoneSettings = ObjectUtil.deepClone(this.tempZoneSettingsHolder);
                    
                    if (this.multiModuleProfile) {
                       
                        for(const keys of this.profiles) {
                            delete (response?.[keys['profile']['name']]?.lastUpdated?.val);
                            if (zoneSettings != null) {
                                delete (zoneSettings?.[keys['profile']['name']]?.lastUpdated?.val);
                            }
                        }
                        if ((!ObjectUtil.isEqual(response, zoneSettings) && !this.userUpdatedValue) || this.overrideCancelled) {
                            this.setZoneSettings(res);
                        }
                        
                    } else {
                        delete (zoneSettings?.lastUpdated?.val);
                        delete (response?.lastUpdated?.val);
                        if ((!ObjectUtil.isEqual(response, zoneSettings) && !this.userUpdatedValue) || this.overrideCancelled) {
                            this.setZoneSettings(res); 
                        }
                    }
                }

            });

    }

    setZoneSettings(res) {
        if(!res) return
        this.zoneSettings = ObjectUtil.deepClone(res);
        this.tempZoneSettingsHolder = ObjectUtil.deepClone(res);
        this.handleHumidityDehumidity();
        this.handleZonePriority();
        this.handleZoneSchedule();
        this.heartBeathandle();
       this.overrideCancelled = false;
        this.ZoneSettingsService.subject.next(this.zoneSettings);
        if (this.zoneSettings && this.zoneSettings.zoneVacation) {
            this.zoneVacations = [].concat(this.zoneSettings.zoneVacation)
        }
        this.showLoadSkeleton = this.zoneSettings?Object.keys(this.zoneSettings).length > 0:false;
        
        this.setSpecialSchedule();
        this.validateValuesAndUpdateSlider();
    }

    setSpecialSchedule() {
        if (this.zoneSettings && this.zoneSettings.zoneSpecialSchedule) {
            this.zoneSettings.zoneSpecialSchedule = [].concat(this.zoneSettings.zoneSpecialSchedule);
        }
    }
/**
 * Here it checks for the properties of zoneSettings and returns false if it is empty
 * else return loaded as true.
 */
    showProfileData() {
        if(!this.zoneSettings) return false;
        let loaded = false;
        let keys = Object.keys(this.zoneSettings);
        for(let i = 0; i < keys.length; i++) {
            if(this.zoneSettings[keys[i]].hasOwnProperty('val')) {
                loaded = true;
                break;
            }
        }
        return loaded;
    }

    calculateAvgCurrentTemp() {
       
        this.avgCurrentTemp = 0;
        let avgTempList = [];
        let profileZoneStatus;
        this.profiles.forEach(profile => {
            if (new RegExp(/^(vav|dab|cpu|pipe2|pipe4|hpu|sse|ti|dualDuct|series|parallel|vrv|chilledBeam)$/).test(profile.profileType)) {

                let filteredProfile = this.multiModuleZoneStatus.filter((x) => { if (x.profile == profile.profile.name) { return x; } })[0];
                profileZoneStatus = (filteredProfile) ? parseInt(filteredProfile.equipStatus) : undefined;
                if (profileZoneStatus != 3)
                    avgTempList.push(parseFloat(this.zoneSettings[profile.profile.name].currentTemp.val));

            }
        });
        var filtered = avgTempList.filter(function (value, index, arr) {
            return (Math.trunc(Number(value)) !== 0);
        });

        this.avgCurrentTemp = (filtered && filtered.length > 0) ?  parseFloat(this.average(filtered).toFixed(1)) : 0;
        this.multipleModuleInput.currentTemp = this.avgCurrentTemp;
        this.zoneInfo.map((zoneInstance) => {
            if (zoneInstance.name == this.zoneName) {
                zoneInstance.currentTemp = this.avgCurrentTemp ? this.avgCurrentTemp.toFixed(1) : 0;
            }
        });
    }
/**
 * Here it sets the coolingDeadbandArray and HeatingDeadbandArray and
 * converts the multipleModuleInput to string form.
 */
    findHighestDeadbands() {
        this.coolingDeadbandArray = [];
        this.heatingDeadbandArray = [];
        this.profiles.forEach(profile => {
            if (profile != undefined) {
                this.coolingDeadbandArray.push(this.zoneSettings[profile.profile.name].coolingDeadband.val);
                this.heatingDeadbandArray.push(this.zoneSettings[profile.profile.name].heatingDeadband.val);
            }
        });

        this.multipleModuleInput.coolingDeadband = (Math.max(...this.coolingDeadbandArray)).toString();
        this.multipleModuleInput.heatingDeadband = (Math.max(...this.heatingDeadbandArray)).toString();
        return this.multipleModuleInput
    }

/**
 * This method will check the value of roomRef and sets the equipRef and sets timezone.
 */
    getEquipRef(zoneId: string) {
        let equipRef;
       let floors = this.helperService.getBuildingComponents(this.selectedZoneInfo, 'floor');
        if(!floors) return;
        let floorData = floors.map(floor => floor.entities?.filter(room => room._id === zoneId))
        floorData.map(floor => {
            if (floor.length > 0) {
                this.roomObj = floor[0];
                equipRef = floor.map(equip => equip.entities.filter(entity => entity.entities.length > 0))[0][0];
                this.roomRef = (equipRef) ? equipRef.referenceIDs.room : 'noRef';
                this.ccuRef = (equipRef) ? equipRef.referenceIDs.ccu : '';
                if (this.roomRef == 'noRef') {
                    this.loaderService.active(false)
                }
                this.refs.roomRef = this.roomRef;
                this.refs.ccuRef = this.ccuRef;
                this.equipRef = equipRef?._id;
                this.refs.equipRef = this.equipRef;
                this.ahuRef = equipRef == undefined ? undefined : (equipRef.referenceIDs.ahu == undefined ? equipRef.referenceIDs.gateway : equipRef.referenceIDs.ahu);
                //Set time zone
                this.vacationsService.getSIteTz(this.refs.siteRef);
                this.vacationsService.siteTimezone.subscribe(tz => this.siteTz = tz);
            }
        })
    }

    getSiteInfo(siteRef) {
        this.siteService.getReadById(siteRef).subscribe(({ rows }) => {
            if (rows?.length) {
                // got data for site
                if (rows[0] && rows[0].tz) {
                    this.siteTz = rows[0].tz;
                }
                this.refs['siteDetails'] = rows[0];
            }
        });
    }

    /**
     * This will return equipName after checking the various profileType.
     */
    formatZoneName(equipName: any) {
        let profileName = this.helperService.setZoneProfileName(equipName);
        return profileName;
    }
/**
 * This returns equipName if profileType is 'pid' or 'sense'.
 */
    isProfilePI(equipName) {
        return equipName?.profileType == 'pid' || equipName?.profileType == 'sense' || equipName?.profileType == 'monitoring';
    }

/**
 * This returns equipName, sets showModbusEmr to true if modBusEquip is 'emr' or 'emr_zone'
 */
    isModbusProfile(equipName) {
        /**if there are previous builds already installed in the site for that we are using  or condition*/
        if(equipName?.modBusEquip == "emr" || equipName?.modBusEquip == "emr_zone") {
            this.showModbusEmr = true
        } else {
            this.showModbusEmr = false
        }
        return equipName?.profileType == 'modbus';
    }

/**
 * This will check for settingType and calls onControlChange
 */
    onSettingsChange(settingType: string = undefined, event: any, profileEquipName: string = undefined) {
        let settingValue = (settingType) ? event.target.value : event.value;
        let settingsObj = (profileEquipName) ? this.zoneSettings[profileEquipName] : this.zoneSettings;
        settingType = (settingType) ? settingType : event.mode;
        this.onControlChange(settingType, settingValue, settingsObj);
    }
/**
 * It calls handleInputChange if event.type == scheduleType and event.val =1 or 0.
 * It calls  onControlChange if event.type == scheduleType and multiModuleProfile= false
 * It calls showForceTrimPopup if event.type == scheduleType and multiModuleProfile= true
 */
    onScheduleOrZonePriorityClick(event: any) {
        if (event.type.includes("zonePriority")) {
            this.oldZonePriorityVal = this.zonePriorityVal;
            this.zonePriorityVal = event.value.toString();
        }
        this.updateScheduleTypeOnClick(event);
        if ((event.type.includes('scheduleType') && parseInt(event.value) == 2)) {
            this.scheduleTypeVal = 2;
        } else {
            if ((event.type.includes("zonePriority") || event.type.includes("scheduleType")) && this.multiModuleProfile) {
                this.updateMultiModuleProfileOnBtnClick(event);
            }
            else {
                this.updateScheduleTypeForSingleModuleProfile(event);
            }
        }
    }

    updateScheduleTypeForSingleModuleProfile(event) {
        if (event.type.includes('scheduleType')) {
            if (parseInt(event.value) == 1) {
                const conflictArr = this.schValidation(event);
                // if there's no conflict
                if (!conflictArr.length) {
                    this.onControlChange(event.type, event.value, this.zoneSettings);
                } else {
                    this.showForceTrimPopup(event);
                }
            } else {
                this.onControlChange(event.type, event.value, this.zoneSettings);
            }
        } else {
            this.onControlChange(event.type, event.value, this.zoneSettings);
        }
    }


    setUserUpdatedValue() {
        this.userUpdatedValue = true;
        setTimeout(()=>{
            this.userUpdatedValue = false;
        },5000)
    }

    updateScheduleTypeOfMultiModuleProfile(event: any) {
        const data = [];
        if (parseInt(event.value) == 1) {
            // zone containment
            const conflictArr = this.schValidation(event);
            // if there's no conflict
            if (!conflictArr.length) {
                    this.multiModuleScheduleTypeList
                        .forEach((r) => {
                            const settingEvtVal = event.value;
                            const settingsObj = (r) ? this.zoneSettings[r.profile] : this.zoneSettings;
                            data.push({
                                type: event.type,
                                value: settingEvtVal,
                                priorityArray: settingsObj[event.type].priorityArray || []
                            });
                        });
                    this.setUserUpdatedValue();
                    this.helperService.handleInputChange(data, true);
            } else {
                this.showForceTrimPopup(event);
            }
        } else {
            this.multiModuleScheduleTypeList
                .forEach((_item) => {
                    const settingValue = event.value;
                    const settingsObj = (_item) ? this.zoneSettings[_item.profile] : this.zoneSettings;
                    data.push({
                        type: event.type,
                        value: settingValue,
                        priorityArray: settingsObj[event.type].priorityArray || []
                    });
                });
            this.setUserUpdatedValue();
            this.helperService.handleInputChange(data, true);
        }
    }

    updateMultiModuleProfileOnBtnClick(event) {
        const data = [];
        this.helperService.multiModulePointsOverride = true;
        if (event.type.includes('zonePriority')) {
                this.multiModuleZonePriorityList.forEach((item) => {
                        const settingVal = event.value;
                        const settingObj = (item) ? this.zoneSettings[item.profile] : this.zoneSettings;
                        data.push({
                                type: event.type,
                                value: settingVal,
                                priorityArray: settingObj[event.type].priorityArray || []
                        });
                });
                this.setUserUpdatedValue();
                this.helperService.handleInputChange(data, true);
        } 
        if (event.type.includes('scheduleType')) {
            this.updateScheduleTypeOfMultiModuleProfile(event);
        }

        this.helperService.multiModulePointsOverride = false;
    }

    updateScheduleTypeOnClick(event: any) {
        if (event.type.includes('scheduleType')) {
            this.oldscheduleTypeVal = this.scheduleTypeVal;
            this.scheduleTypeVal = event.value.toString();
            if (parseInt(event.value) != 2) {
                let refs = ObjectUtil.deepCopy(this.refs);
                refs['scheduleId'] = this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['zone', 'schedule', 'days'], this.roomRef);
                refs['floorRef'] = this.roomObj['referenceIDs']['floor'];
                this.namedScheduleService.refs = refs;
            }
        }
    }

    onControlChange(mode: string, inputData: any, priorityArray: any) {
        let data = []
        let addEntity = mode.includes('scheduleType') ? true: false;
        data.push({
            type: mode,
            value: inputData,
            priorityArray: priorityArray[mode].priorityArray || []
        });
        this.setUserUpdatedValue();
        this.helperService.handleInputChange(data, addEntity);
    }

    UpdateBuildingsDataForVacationEdit(entityid: any) {
        let query = `(floor or ccu or room or ((zone and equip) or (roomRef != @SYSTEM and roomRef==@${this.roomRef}))) and siteRef==@` + this.siteRef;
        this.siteService.findByQuery(query).pipe(
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure),
            map(this.helperService.stripHaystackTypeMapping),
            ).subscribe(
            (response: any) => {
                this.selectedZoneInfo = response;
                this.selectedZoneInfo['entities'] = ArrayUtil.deepFlatten(response, 'entities');
                this.selectedZoneInfo.entities.map((ent) => {
                    if (ent.type == "room" && ent._id == this.refs.roomRef) {
                        this.updateZoneEntitesOnMessage(entityid,ent)
                    }
                })

            },
        );
    }

    updateZoneEntitesOnMessage(entityid,ent) {
        let roomVacationUpdated;
        let vacationids = this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['schedule', 'range', 'vacation'], ent._id);
        let specialscheduleIds = this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['schedule', 'range', 'special'], ent._id);
        if (vacationids.includes(entityid) || specialscheduleIds.includes(entityid)) {
            roomVacationUpdated = ent._id;
            if(vacationids.includes(entityid)) {
                this.helperService.updatePointIdData(entityid, 'vacation', 'zoneVacation', undefined, 'update');
            }
            if(specialscheduleIds.includes(entityid)) {
                this.helperService.updatePointIdData(entityid, 'vacation', 'zoneSpecialSchedule', undefined, 'update');
            }
            
            this.getUserSettingData("vacationAdded");
            
        }
    }

/**
 * Here it checks for selectedbuildingexists. If not it calls setBuildingData.
 */
    getZones(siteRef: string) {
        let query = "(room or ccu or floor or equip or (building and occupancy) or (schedulable and default) or (building and limit and (min or max))) and siteRef==@" + siteRef;
        this.siteService.findByQuery(query).pipe(
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure),
        ).subscribe(
            (response: any) => {
                this.buildingMaster = response;
                const entities = ArrayUtil.deepFlatten(response, 'entities');
                this.buildings['entities'] = entities;
                this.buildings.floors = this.helperService.getBuildingComponents(this.buildingMaster, 'floor');
                this.buildings.buildingOccupancy = this.helperService.getBuildingComponents(this.buildingMaster, 'buildingoccupancy');
                this.buildings.ccus = this.helperService.getBuildingComponents(this.buildingMaster, 'ccu');
                this.buildings.equips = this.helperService.getBuildingComponents(this.buildingMaster, 'equip');
                this.selectedBuilding.ccus = this.buildings.ccus;
                this.selectedBuilding.rooms = entities?.filter(e=>e.markers.indexOf('room')>-1);

                this.zoneInfo = this.selectedBuilding.rooms;
                if(!this.zoneInfo.length) this.showLoadSkeleton = true;
                this.currentTemperatureForAllZones();
                this.selectZoneOnLoad();
                
            },
        );
        this.refs.siteRef = siteRef;
        this.getSiteInfo(siteRef);
        this.subscriptions['pubNubService'] = this.pubNubService.subscribe([siteRef]);
    }

    searchZone() {
        let search = (<HTMLInputElement>document.getElementById('inputZoneName')).value;
        let filter = search.toUpperCase();
        let ul = document.getElementsByClassName("zonepage-wrapper__nav__zoneList");
        let li = ul[0].querySelectorAll('li');
        for (let i = 0; i < li.length; i++) {
            let bName = li[i].getElementsByTagName('div')[0];
            let txtValue = bName.textContent || bName.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }

    /**
     * This will assign the value for multiModuleZoneStatus array.
     */
    fetchequipstatus() {
        this.multiModuleZoneStatus = [];
        let pointsList = [];

        pointsList = this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['zone', 'his', 'status', 'enum'], this.refs.roomRef,['diag']);
        if(pointsList?.length == 0) return;
         this.siteService.getCurrenthisReadMany(
            pointsList
        ).pipe(takeUntil(this.unsubscribe)).subscribe(({ rows }) => {
            if (rows && rows.length > 0) {
                rows.forEach((row) => {

                    let desc = row.dis;
                    let substringpos = desc.lastIndexOf("-");
                    let profile = desc.slice(0, substringpos);
                    let zonedeadStatus = row.data[0] ? this.helperService.stripHaystackTypeMapping(row.data[0].val).split(' ')[0] : undefined;
                    this.multiModuleZoneStatus.push({ 'profile': profile, 'equipStatus': zonedeadStatus });
                });
            }
            else {
            }
           
        },(err) => {
            });
    }

    currentTemperatureForAllZones() {
        let zoneStatusQuery = '((zone and equip) or ((space or current) and temp and zone and his) or (heartbeat or heartBeat) or (zone and his and status and enum and not diag)) and siteRef==@'+this.siteRef;
       
        this.siteService.findByQuery(zoneStatusQuery).pipe(
            map(this.helperService.stripHaystackTypeMapping)
        ).subscribe(res=>{
            let zoneData = ArrayUtil.groupBy(res?.rows?.filter(point=>point.hasOwnProperty('equip'))||[],'roomRef') || [];
            let pointList = res?.rows?.filter(point=>point.hasOwnProperty('his'))?.map(point=>point.id);
            if(pointList.length) {
                console.log(pointList.length);
                this.siteService.getCurrenthisReadMany(
                    pointList
                ).pipe(
                    map(this.helperService.stripHaystackTypeMapping)
                ).subscribe(({ rows }) => {
                    let data = ArrayUtil.groupBy(rows || [], 'roomRef');
                    this.zoneInfo.forEach((zone, index) => {
                        let roomObj = this.returnRoomObj(zone.name);
                        let profiles = this.deviceHelper.getZoneProfile(roomObj);
                        if (profiles[0]?.profileType) {
                            this.zoneInfo[index].profile = profiles[0]?.profileType;
                        } else {
                            this.zoneInfo[index].profile = '';
                        }
                        this.allZonesData[zone._id] = {
                            online: this.checkZoneOnline(data?.[zone._id] || [], zoneData?.[zone._id]),
                            zonePaired: zoneData?.[zone._id]?.length > 0,
                            //Note here we are setting profile type  for displaying the zones sides list 
                            // it will set for multi module also as PI EMR will not be paired with temp profiles 
                            profileType: this.zoneInfo[index].profile,
                            currentTemp: this.getZoneCurrentTemp(data?.[zone._id] || [], zoneData?.[zone._id])
                        }
                    })
                   
                },err=>{
                    console.log(err)
                });
            } else {
                console.log('no points')
            }

        },err=>{
            console.log(err);
        })
    }

    getZoneCurrentTemp(zoneData, zoneEquipData) {
        let currentTempPoints = zoneData?.filter(point=> point.hasOwnProperty('temp') && (point.hasOwnProperty('space') || point.hasOwnProperty('current')));
        if(currentTempPoints?.length) {
            if(zoneEquipData.length == 1) {
                let currentTempValue = currentTempPoints[0]?.data?.[0]?.val?.split(' ')?.[0] || '';
                return this.tempConversion(currentTempValue);
            } else {
                let currentTempList = [];
                let equipStatus = zoneData?.filter(point=> point.hasOwnProperty('zone') && (point.hasOwnProperty('status')));
                currentTempPoints.forEach((curentTemp) => {
                    let status = equipStatus.find(status=>status.equipRef == curentTemp.equipRef)?.data?.[0]?.val;
                    if(status != 3) {
                        currentTempList.push(parseFloat(currentTempPoints[0]?.data?.[0]?.val?.split(' ')?.[0]));
                    }
                });

                currentTempList = currentTempList.filter((x) => !isNaN(x) && ObjectUtil.isNotUndefinedOrNull(x) && x != 0);
                if (currentTempList.length == 0) {
                    return '--';
                } else {
                    return this.tempConversion(parseFloat(this.average(currentTempList).toFixed(1)))
                }
                

            }
        } 
        return '--';

    }

    tempConversion(val) {
        if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
            return this.unitService.fahrenheitToCelecius(val,'currentTemperature');
        } 
        return val
    }

  /**
   * Here it checks for the last updated value and returns a boolean value."
   */
    checkZoneOnline(zoneData, zoneEquipData) {
        if(zoneData?.length) {
            let heartbeatPoints = zoneData.filter(point=> point.hasOwnProperty('heartbeat') || point.hasOwnProperty('heartBeat'));
            let heatbeatValues = heartbeatPoints.map(point => point?.data?.[0]?.ts?.split(' ')?.[0] || '');
            if(zoneEquipData?.length != heatbeatValues?.length) return false;

            return heatbeatValues.every((lastUpdatedTime) => {
                lastUpdatedTime =  dayjs.utc(lastUpdatedTime).local();
                let timeDiffMinutes = Math.abs(lastUpdatedTime.diff(dayjs(), 'minutes'));
                return timeDiffMinutes < 15;
            });
        } 
        return false;
    } 

    resetInfo() {
        this.zonePriorityVal = undefined;
        this.oldZonePriorityVal = undefined;
        this.scheduleTypeVal = undefined;
        this.oldscheduleTypeVal = undefined;
        this.multiModuleZonePriorityList = new Map();
        this.multiModuleScheduleTypeList = new Map();
        this.multiModuleProfile = false;
        this.zonePriorityCount = 0;
        this.zoneScheduleCount = 0;
        this.cancelChanges = [];
        this.buildingLimit = {
            min: undefined,
            max: undefined
        };
        this.sliderInput = {
            currentTemp: undefined,
            desiredTempHeating: undefined,
            desiredTempCooling: undefined,
            heatingUserLimitMin: undefined,
            heatingUserLimitMax: undefined,
            coolingUserLimitMin: undefined,
            coolingUserLimitMax: undefined,
            coolingDeadband: undefined,
            heatingDeadband: undefined,
        };
        this.sliderUserIntentService.clearData();
        this.zoneSettings = {
            currentTemp: undefined,
            desiredTempHeating: undefined,
            desiredTempCooling: undefined,
            heatingUserLimitMin: undefined,
            heatingUserLimitMax: undefined,
            coolingUserLimitMin: undefined,
            coolingUserLimitMax: undefined,
            coolingDeadband: undefined,
            heatingDeadband: undefined,
            equipScheduleStatus: undefined,
            scheduleType: undefined,
            equipStatusMessage: undefined,
            damperPos: undefined,
            reheatPos: undefined,
            enteringAirTemp: undefined,
            dischargeAirTemp: undefined,
            zonePriority: undefined,
            ConditionMode: undefined,
            fanMode: undefined,
            buildingSchedule: undefined,
            zoneSchedule: undefined,
            zoneVacation: undefined,
            currentRate: undefined,
            energyReading: undefined,
            inputValue: undefined,
            offsetValue: undefined,
            targetValue: undefined,
            dynamicSetpointValue: undefined,
            masterOperationMode: undefined,
            masterControllermode: undefined,
        };

        this.tempZoneSettingsHolder = ObjectUtil.deepClone(this.zoneSettings);

        this.zoneVacations = [];
        Object.keys(this.getUserSettingDataSubscriptionCollection).forEach((e) => {
          if(this.getUserSettingDataSubscriptionCollection[e]){
            this.getUserSettingDataSubscriptionCollection[e].unsubscribe()
          };
            delete this.getUserSettingDataSubscriptionCollection[e];
        });
        this.helperService.clearData();
        this.displayRangeSlider = undefined;

    }

    /**
     * This sets the humidifier to value('hyperstat' or ) if humidifier or dehumidifier is set true.
     */

    handleHumidityDehumidity() {
        let hyperStatProfileName = this.profileObj?.profile?.markers?.find(e => e === 'hyperstatsplit' || e === 'hyperstat');
        if (hyperStatProfileName?.length || this.multiModuleProfile) {
            let keys = ['relay1Association', 'relay2Association', 'relay3Association', 'relay4Association', 'relay5Association', 'relay6Association']
            let relayKeysStatus = ['relay1', 'relay2', 'relay3', 'relay4', 'relay5', 'relay6']
            if (hyperStatProfileName == 'hyperstatsplit') {
                keys = [...keys, 'relay7Association', 'relay8Association']
                relayKeysStatus = [...relayKeysStatus, 'relay7', 'relay8']
            }
                if (this.profiles && this.profiles.length) {
                    this.profiles.forEach((_item, _index) => {
                        if (_item['profileType'] == 'cpu' || _item['profileType'] == 'cpuecon'|| _item['profileType'] == 'hpu'  || _item['profileType'] == 'pipe2') {
                            let profileName = _item['profile']?.['name'] ? _item['profile']['name'] : '';
                                    let relayVal;
                                    let relayEnabled
                                for (let i = 0; i < keys.length; i++) {
                                    let profileTypeCheck = _item['profileType']
                                    const relayKey = profileTypeCheck ==  _item['profileType'] ? keys[i] : '';
                                    const relayKeyStatus = profileTypeCheck ==  _item['profileType'] ? relayKeysStatus[i] : '';
                                    if (this.profiles.length > 1) {
                                        relayVal = this.setRelayproperties(profileName,relayKey);
                                        relayEnabled = this.setRelayproperties(profileName,relayKeyStatus);
                                    } else {
                                        relayVal = this.relaypropertForEmptyProfiles(relayKey);
                                        relayEnabled = this.relaypropertForEmptyProfiles(relayKeyStatus);
                                    }
                                    if (!_item[relayKey] || (_item[relayKey] != relayVal)) {
                                        _item[relayKey] = relayVal;

                                        if ( _item['profileType'] == 'pipe2' && relayVal == 8 && relayEnabled == 1   || _item['profileType'] == 'cpu' && relayEnabled ==1  &&  relayVal == 11|| _item['profileType'] == 'cpuecon' && relayEnabled ==1  &&  relayVal == 11 ||  _item['profileType'] == 'hpu' && relayEnabled ==1 && relayVal == 10) {
                                            _item['tags'] = ObjectUtil.insertKey('targetHumidity', ['zone', 'target', 'humidifier'], _item['tags'], 1);
                                            this.profiles[_index] = Object.assign({}, _item);
                                        } 
                                        
                                    
                                        if ( _item['profileType'] == 'pipe2' && relayEnabled ==1 && relayVal == 9 ||   _item['profileType'] == 'cpu' &&  relayEnabled ==1 && relayVal == 12 || _item['profileType'] == 'cpuecon' &&  relayEnabled ==1 && relayVal == 12 ||  _item['profileType'] == 'hpu' && relayEnabled ==1 && relayVal == 11 ) {
                                            _item['tags'] = ObjectUtil.insertKey('targetDehumidity', ['zone', 'target', 'dehumidifier'], _item['tags'], 1);
                                            this.profiles[_index] = Object.assign({}, _item);
                                        }
                                    }

                            }
                        }
                    });
        
            }
        } else {

            if (this.profiles && this.profiles.length) {
                this.profiles.forEach((_item, _index) => {
                    if (_item['profileType'] == 'cpu' || _item['profileType'] == 'hpu') {
                        let relayKey = _item['profileType'] == 'cpu' ? 'relay6Type' : 'relay5Type';
                        let profileName = _item['profile'] && _item['profile']['name'] ? _item['profile']['name'] : '';
                        let relayVal;
                        if (this.profiles.length > 1) {
                            relayVal = (this.zoneSettings && profileName && this.zoneSettings[profileName]) ?
                                ((this.zoneSettings[profileName][relayKey] && this.zoneSettings[profileName][relayKey]['val']) ?
                                    (this.zoneSettings[profileName][relayKey]['val'] || '') : '') : '';
                        } else {
                            relayVal = (this.zoneSettings) ?
                                ((this.zoneSettings[relayKey] && this.zoneSettings[relayKey]['val']) ?
                                    (this.zoneSettings[relayKey]['val'] || '') : '') : '';
                        }
                        if (!_item[relayKey] || (_item[relayKey] != relayVal)) {
                            _item[relayKey] = relayVal;
                            if (relayVal == '2') { // Adding targetHumidity
                                _item['tags'] = ObjectUtil.insertKey('targetHumidity', ['zone', 'target', 'humidity'], _item['tags'], 1);
                                _item['tags']['targetDehumidity'] ? delete _item['tags']['targetDehumidity'] : '';
                                this.profiles[_index] = Object.assign({}, _item);
                            } else if (relayVal == '3') { // Adding targetDehumidity
                                _item['tags'] = ObjectUtil.insertKey('targetDehumidity', ['zone', 'target', 'dehumidifier'], _item['tags'], 1);
                                _item['tags']['targetHumidity'] ? delete _item['tags']['targetHumidity'] : '';
                                this.profiles[_index] = Object.assign({}, _item);
                            } else {
                                _item['tags']['targetHumidity'] ? delete _item['tags']['targetHumidity'] : '';
                                _item['tags']['targetDehumidity'] ? delete _item['tags']['targetDehumidity'] : '';
                                this.profiles[_index] = Object.assign({}, _item);
                            }
                        }
                    }
                });
            }
        }

    }


    setRelayproperties(profileName, relayKey) {
        let relay
        if (this.profiles.length > 1) {
            if (this.zoneSettings && profileName && this.zoneSettings[profileName]) {

                if (this.zoneSettings[profileName][relayKey] && this.zoneSettings[profileName][relayKey]['val']) {
                    relay = this.zoneSettings[profileName][relayKey]['val'] || '';
                }
            } else {
                relay = ''
            }
        }
        return relay;

    }

    relaypropertForEmptyProfiles(relayKey) {
        let relay;
        if (this.zoneSettings) {
            if (this.zoneSettings[relayKey] && this.zoneSettings[relayKey]['val']) {
                relay = this.zoneSettings[relayKey]['val'] || '';
            } else {
                relay = ''
            }
        } else {
            relay = ''
        }

        return relay;
    }

    getZoneData() {
        
        let query = `(floor or ccu or room or ((zone and equip) or (roomRef != @SYSTEM and roomRef==@${this.roomRef}))) and siteRef==@` + this.siteRef;
        this.siteService.findByQuery(query).pipe(
            map(this.helperService.listEntities),
            map(this.helperService.createEntityStructure),
            map(this.helperService.stripHaystackTypeMapping),
            ).subscribe(
            (response: any) => {
            this.selectedZoneInfo = response;
            this.selectedZoneInfo['entities'] = ArrayUtil.deepFlatten(response, 'entities');

            this.getEquipRef(this.roomRef)
            this.equipObj =this.getEquip(['system', 'equip', 'profile']) || [];
            this.getEquipRef(this.roomRef);
            this.getCcuVersion();
            this.profiles = this.deviceHelper.getZoneProfile([this.roomObj]);
            this.modbusProfileSort();
            this.multiModuleProfile = this.profiles?.length > 1 ? true : false;
            if (this.multiModuleProfile) {
                this.fetchequipstatus();
                const profiles = Array.from(this.profiles)
                profiles.forEach((_item) => {
                    if (_item && ['vav', 'dab', 'ti', 'series', 'parallel','otn', 'bpos', 'vrv','chilledBeam'].includes(_item['profileType'])) {
                        this.zonePriorityCount++;
                    }
                    if (_item && ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti', 'dualDuct', 'series', 'parallel','chilledBeam'].includes(_item['profileType'])) {
                        this.zoneScheduleCount++;
                    }
                    if (_item && ['vav', 'dab', 'ti', 'series', 'parallel', 'vrv','chilledBeam'].includes(_item['profileType'])) {
                        this.zonePriorityCount++;
                    }
                    
                })
            }

            this.profileCheck(this.profiles);
            this.getProfileData(this.profiles);
            this.getUserSettingData("selectzone");
            
            // start the setInterval call to call the api to update the data change if any every 5 sec
            this.messagingSubscribe = setInterval(() => {
                this.helperService.clearWritablePointsUpdatedStatus();
                this.helperService.getPointData().subscribe(res => res);
            }, 5000);
            },error=>{
                console.log(error);
            }
        );
    }


    selectzone(zone) {
        if(this.roomRef == zone._id) {
            return;
        }
       
        this.showLoadSkeleton = false;
        clearInterval(this.messagingSubscribe);
        this.helperService.clearData();
        this.zoneSettings = null;
        this.tempZoneSettingsHolder = null;
        this.overrideCancelled = false;
        this.ccuRef = undefined;
        this.zoneName = zone.name;
        this.refs['zoneName'] = zone.name;
        this.resetInfo();
        this.humidifier = false;
        this.deHumidifier = false;
        this.roomRef = zone._id;
        this.ccuRef = zone.ccuRef;
        this.refs.roomRef = this.roomRef;
        this.refs.ccuRef = this.ccuRef;
        this.refs.equipRef = this.equipRef;
        this.refs.siteRef = this.siteRef;
        this.profiles = [];
        this.getZoneData();
        
       
    }

    modbusProfileSort() {
        //The below conditional checks is applicable for only modbus profiles.
       //Based on port number child and parent equips are classified.
       let portNumber: Number;
       this.profiles.forEach(x => {
           this.isModbus = x.profileType == "modbus" ? true : false;
           if (!this.isModbus) {
               x['profileName'] = x.profile.name;
               return;
           }
            x['profileName'] = `${x.profile.name}_${x.profile._id}`;
           if ((!x.profile?.referenceIDs?.hasOwnProperty('equip')) && this.isModbus) {
               portNumber = x.portNum;;
               x['isChild'] = false;
           }

           if (this.isModbus && (x.profile?.referenceIDs?.hasOwnProperty('equip') && x.portNum == portNumber)) {
               x['isChild'] = true;
           } else if (this.isModbus && (x.profile?.referenceIDs?.hasOwnProperty('equip') && x.portNum != portNumber)) {
               x['isChild'] = false;
           }
       })

    }

    profileCheck(profile) {
        if (profile[profile.length - 1]) {
            this.profileCheckFlag = ['vav', 'dab', 'cpu', 'hpu', 'pipe2', 'pipe4', 'sse', 'ti', 'dualDuct', 'series', 'parallel','otn','bpos','vrv','chilledBeam'].includes(profile[profile.length - 1].profileType);
        }

    }

/**
 * This will assign the priorityArray with pointId
 */
    getZoneSettings(settingType: string, pointID: any, endPointType: string, profileEquipName: string = undefined) {
        if (pointID) {
            pointID.map((pId) => this.helperService.assemblePointIdData(pId, endPointType, settingType, profileEquipName, 'update'));
            Object.assign(this.priorityArray, { [settingType]: {} });
            this.priorityArray[settingType]['id'] = pointID;
        }
    }

    dateFormat(val) {
        return DateUtil.dateFormat(val,this.siteService.getIANATimeZone(this.siteTz));
      }

    desiredTempsChangedHandler(changedbuttonid: PucUserIntentTempSliderValueChangedIds) {
        let datalastupdatedby = this.sliderUserIntentService.getLatestUpdatedBy(this.zoneName);
        this.sliderdata = this.sliderUserIntentService.getData(datalastupdatedby, this.zoneName);
        let heatingdesiredvalue = this.sliderdata.desiredTempHeating;
        let coolingdesiredvalue = this.sliderdata.desiredTempCooling;

         /**Temperature Unit prefernce conversions**/
         if(this.userTempPreference == this.unitService.temperatureUnitListArray[0]) {
            heatingdesiredvalue =  this.unitService.celeciusToFahrenheit(this.sliderdata.desiredTempHeating)
            coolingdesiredvalue =  this.unitService.celeciusToFahrenheit(this.sliderdata.desiredTempCooling)
        } else {
            heatingdesiredvalue = this.sliderdata.desiredTempHeating;
            coolingdesiredvalue = this.sliderdata.desiredTempCooling;
        }
        
        if (changedbuttonid == PucUserIntentTempSliderValueChangedIds.Cooling || changedbuttonid == PucUserIntentTempSliderValueChangedIds.Deadband)
            this.coolingDesriedTempChangeHolder.push({
                "type": 'desiredTempCooling',
                "temp": coolingdesiredvalue
            });
        if (changedbuttonid == PucUserIntentTempSliderValueChangedIds.Heating || changedbuttonid == PucUserIntentTempSliderValueChangedIds.Deadband)

            this.heatingDesriedTempChangeHolder.push({
            "type": 'desiredTempHeating',
            "temp": heatingdesiredvalue
            });
        this.onChangeDesiredTempSubject.next({
            type: '', temp: ''
        })
    }

    onChangeDesiredTemp(settingType: any[]) {
        let data = [];
        if (this.multiModuleProfile) {
            if (!settingType) settingType = [];
            settingType.forEach((type) => {
                this.profiles.forEach(profile => {
                    if (profile != undefined) {
                        let profileEquipName = profile.profile.name;
                        data.push({
                            type: type.name,
                            value: type.value,
                            priorityArray: this.zoneSettings[profileEquipName][type.name].priorityArray || []
                        })
                    }
                });


            })
            this.setUserUpdatedValue();
            this.helperService.handleInputChange(data);

        } else {
            if (!settingType) settingType = [];
            settingType.forEach((type) => {
                this.profiles.forEach(profile => {
                    if (profile != undefined) {
                        data.push({
                            type: type.name,
                            value: type.value,
                            priorityArray: this.zoneSettings[type.name].priorityArray || []
                        })
                    }
                });

            })
            this.setUserUpdatedValue();
            this.helperService.handleInputChange(data);
        }
    }

/**
 * This will call helperService.revertPointValues when ccuOrZone.type == "ccu".
 * Should call helperService.revertPointValues when ccuOrZone.type == "zone"
 */
    revertChangedValues(paramList: any) {

        for (let i = paramList.length - 1; i >= 0; i--) {
            if (!this.multiModuleProfile && this.zoneSettings[paramList[i].mode]) {
                this.zoneSettings[paramList[i].mode].val = parseFloat(paramList[i].val)
            }
          
            if (paramList[i].mode == "zonePriority") {
                this.zonePriorityVal = this.oldZonePriorityVal;
            }
            else if (paramList[i].mode == "scheduleType") {
                this.scheduleTypeVal = this.oldscheduleTypeVal;
            }
            paramList.splice(i);
            this.helperService.revertPointValues.splice(i);

        }
    }
/**
 * It checks for zoneSchedule and buildingSchedule and calls zoneContainment.
 */
    schValidation(event) {
        if (this.zoneSettings?.zoneSchedule?.val && this.zoneSettings?.buildingOccupancy?.val) {
            this.forceTrimArray = [];
            this.conflictMsg = '';
            
            let arr = (a, b) => {
                return parseInt(a?.day) - parseInt(b?.day) || parseInt(a?.sthh) - parseInt(b?.sthh);
            }
            let zoneSchInfo = this.zoneSettings?.zoneSchedule?.val;
            zoneSchInfo?.sort(arr);

            zoneSchInfo?.forEach(zoneSch => {
                this.zoneContainment(zoneSch);
            });


            return this.forceTrimArray;

        }
    }

    //method to force trim
    forceTrim() {
        if (this.forceTrimArray.length) {
            let zoneInfo = this.zoneSettings.zoneSchedule.val;
            return this.schedulerService.forceTrim(this.forceTrimArray, zoneInfo);

        }
    }

    // Group the data based on the selected grouping mode
    formatGroupedData(groupedData) {
        const currentSelectedGroup = this.groupingOptions.find(group => group.value == this.zoneSettings.zoneSchedule.scheduleGroup);
        let formattedGroupedData = {};
        const flattenGroupedData = (groupedData) => {
            return Object.values(groupedData).flat();
        };
        const removeDuplicates = (targetArray) => {
            return [...new Set(targetArray)];
        };

        // Grouping logic based on the selected mode
        switch (currentSelectedGroup?.enum) {
            case 'EVERYDAY':
                formattedGroupedData = { "All Days": removeDuplicates(flattenGroupedData(groupedData)) };
                break;
            case 'WEEKDAY_WEEKEND':
                formattedGroupedData = {
                    "Weekday": removeDuplicates(flattenGroupedData(this.pickDays(groupedData, [0, 1, 2, 3, 4]))),
                    "Weekend": removeDuplicates(flattenGroupedData(this.pickDays(groupedData, [5, 6])))
                };
                break;
            case 'WEEKDAY_SATURDAY_SUNDAY':
                formattedGroupedData = {
                    "Weekday": removeDuplicates(flattenGroupedData(this.pickDays(groupedData, [0, 1, 2, 3, 4]))),
                    "Saturday": removeDuplicates(groupedData["SATURDAY"] || []),
                    "Sunday": removeDuplicates(groupedData["SUNDAY"] || [])
                };
                break;
            case '7_DAY':
                formattedGroupedData = this.pickDays(groupedData, [0, 1, 2, 3, 4, 5, 6]);
                break;
        }
        formattedGroupedData = this.getOrderedData(formattedGroupedData);
        return formattedGroupedData;
    }

    // Order the grouped data based on the selected grouping mode
    getOrderedData(groupedData) {
        const currentSelectedGroup = this.groupingOptions.find(group => group.value == this.zoneSettings.zoneSchedule.scheduleGroup);
        const selectedGroupEnum = currentSelectedGroup?.enum;
        const getOrderedDays = (selectedGroupEnum) => {
            switch (selectedGroupEnum) {
                case 'EVERYDAY':
                    return ['All Days'];
                case 'WEEKDAY_WEEKEND':
                    return ['Weekday', 'Weekend'];
                case 'WEEKDAY_SATURDAY_SUNDAY':
                    return ['Weekday', 'Saturday', 'Sunday'];
                case '7_DAY':
                    return ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
            }
        }
        const orderedDays = getOrderedDays(selectedGroupEnum);
        const orderedGroupedData = [];
        orderedDays?.forEach(day => {
            if (groupedData[day] && groupedData[day].length) {
                orderedGroupedData.push({ day, times: groupedData[day] });
            }
        });
        return orderedGroupedData;
    }

    // Pick the days based on the selected grouping mode
    pickDays(groupedData, dayIndexes) {
        return dayIndexes.reduce((acc, dayIndex) => {
            const dayName = weeks[dayIndex];
            if (groupedData[dayName]) {
                acc[dayName] = groupedData[dayName];
            }
            return acc;
        }, {});
    }

    /**
     * It will call onControlChange() when event.type.includes("zonePriority")
     */
    showForceTrimPopup(event) {
        let groupedData: any = {};
        if (this.forceTrimArray.length) {
            this.forceTrimArray.forEach(item => {
                let day = weeks[item.index];
                if (!groupedData[day]) {
                    groupedData[day] = [];
                }
                groupedData[day].push(item.trim);
            });
            groupedData = this.formatGroupedData(groupedData);
        } else {
            groupedData = null;
        }
        groupedData?.map(_grp => {
            _grp.times = this.schedulerService.mergeOverlappingRanges(_grp.times);
            _grp.times = _grp.times.map(time => this.schedulerService.convertTo12HourFormat(time));
            return _grp.times;
        });
        const dialogRef: any = this.dialog.open(SchedulerForceTrimModalComponent, {
            panelClass: 'fs-mat-dialog-container',
            width: '533px',
            maxHeight: '400px'
        });
        const currentSelectedGroup = this.groupingOptions.find(group => group.value == this.zoneSettings.zoneSchedule.scheduleGroup);
        dialogRef.componentInstance.discardText = 'Re-Edit';
        dialogRef.componentInstance.saveText = 'Force-Trim & Save';
        dialogRef.componentInstance.showConfirmIcon = true;
        dialogRef.componentInstance.data = groupedData;
        dialogRef.componentInstance.title = 'Zone Schedule is outside Building Occupancy';
        dialogRef.componentInstance.groupName = currentSelectedGroup?.displayName;
        if (this.forceTrimArray.length) {
            dialogRef.afterClosed().subscribe(result => {
                if (!result) {
                    this.scheduleTypeVal = this.oldscheduleTypeVal;
                }
                if (result == 'confirm') {
                    let zoneSchInfo = this.forceTrim();
                    zoneSchInfo = this.forceTrimForSchedulGroup(zoneSchInfo);
                    zoneSchInfo = zoneSchInfo.map(sch => {
                        return ObjectUtil.removeKey('isSplit', sch);
                    })

                    //save zone schedule
                    let schId = this.helperService.getPointIdbyTags(this.selectedZoneInfo, ['zone', 'schedule', 'days'], this.refs.roomRef)
                    this.siteService.updateSchedule(
                        schId[0],
                        this.helperService.stripHaystackTypeMapping(this.roomRef),
                        this.helperService.stripHaystackTypeMapping(this.ccuRef),
                        this.helperService.stripHaystackTypeMapping(JSON.stringify(zoneSchInfo)).replace(/['"]+/g, '').replace(/(:[\d\.]+)(,)/g, '$1 '),
                        'zoneRef',
                        this.helperService.stripHaystackTypeMapping(this.siteRef),
                        this.zoneSettings?.zoneSchedule?.unoccupiedZoneSetback,
                        this.zoneSettings?.zoneSchedule?.followBuilding,
                        this.zoneSettings?.zoneSchedule?.scheduleGroup,
                    ).pipe(
                        map(this.helperService.stripHaystackTypeMapping),
                        takeUntil(this.unsubscribe)
                    ).subscribe(res => {
                        if (res.rows) {
                            this.zoneSettings.zoneSchedule.val = res.rows[0].days;
                        }
                    });
                    if (event.type.includes("zonePriority") || event.type.includes("scheduleType") && this.multiModuleProfile) {
                        this.multiModuleScheduleTypeList
                            .forEach((_item) => {
                                let settingValue = event.value;
                                let settingsObj = (_item) ? this.zoneSettings[_item.profile] : this.zoneSettings;
                                this.onControlChange(event.type, settingValue, settingsObj)
                            })
                    }
                    else {
                        this.onControlChange(event.type, event.value, this.zoneSettings);
                    }
                }
            });
        }
        else {
            dialogRef.close();
        }
    }

    forceTrimForSchedulGroup(scheduleInfo) {
        const scheduleGroup = this.zoneSettings?.zoneSchedule?.scheduleGroup;
        if (parseInt(scheduleGroup)) {
            if (parseInt(scheduleGroup) == 3) {
                let everydayScheduleInfo = ObjectUtil.deepClone(scheduleInfo);
                everydayScheduleInfo = everydayScheduleInfo.sort((a,b) => Number(a.day) - Number(b.day));
                everydayScheduleInfo = this.schedulerService.findCommonTimeRanges(everydayScheduleInfo, 0, parseInt(scheduleGroup));
                scheduleInfo = ObjectUtil.deepClone(everydayScheduleInfo);
            } else if (parseInt(scheduleGroup) == 2) {
                let weekDayscheduleInfo = ObjectUtil.deepClone(scheduleInfo.filter(item => item.day != 5 && item.day != 6));
                let weekEndscheduleInfo = ObjectUtil.deepClone(scheduleInfo.filter(item => item.day == 5 || item.day == 6));
                const weekdaySchInfo = this.schedulerService.findCommonTimeRanges(weekDayscheduleInfo, 0, parseInt(scheduleGroup));
                const weekendSchInfo = this.schedulerService.findCommonTimeRanges(weekEndscheduleInfo, 5, parseInt(scheduleGroup));
                scheduleInfo = [...weekdaySchInfo, ...weekendSchInfo];
            } else if (parseInt(scheduleGroup) == 1) {
                const weekDayscheduleInfo = ObjectUtil.deepClone(scheduleInfo.filter(item => item.day != 5 && item.day != 6));
                const weekdaySchInfo = this.schedulerService.findCommonTimeRanges(weekDayscheduleInfo, 0, parseInt(scheduleGroup));
                const weekendSchInfo = ObjectUtil.deepClone(scheduleInfo.filter(item => item.day == 5 || item.day == 6));
                scheduleInfo = [...weekdaySchInfo, ...weekendSchInfo];
            }
        }
        return scheduleInfo;
    }

    //checks If ZoneSchedule's Exceeding BuildingSchedule
    //returns true or false
    zoneContainment(zoneSch) {

        let zoneSchStartTime = dayjs(zoneSch['sthh'] + ':' + zoneSch['stmm'], 'HH:mm');
        let zoneSchEndTime = dayjs(zoneSch['ethh'] + ':' + zoneSch['etmm'], 'HH:mm');
        let buildingSchInfo = this.zoneSettings.buildingOccupancy.val;
        let forceTrimArray = this.schedulerService.zoneContainment(buildingSchInfo,zoneSch.day,zoneSchStartTime,zoneSchEndTime);
        this.forceTrimArray = this.forceTrimArray.concat(forceTrimArray);
    }
    combineAdjacentSch(overlapArr, key, updateKey?) {
        for (let i = 0; i < overlapArr.length - 1;) {
            let index = parseInt(overlapArr[i][key]) + 1
            index = index > 6 ? 0 : index;
            if (overlapArr[i].end.hours() == 0 && overlapArr[i].end.minutes() == 0 && overlapArr[i + 1].start.hours() == 0 && overlapArr[i + 1].start.minutes() == 0 && index == parseInt(overlapArr[i + 1][key]) && overlapArr[i + 1].isSplit && overlapArr[i].isSplit) {
                overlapArr[i].end = overlapArr[i + 1].end;
                if (updateKey) {
                    overlapArr[i][updateKey] = overlapArr[i].start.format('HH:mm') + "-" + overlapArr[i + 1].end.format('HH:mm');
                }
                overlapArr.splice(i + 1, 1);
            } else {
                i++
            }
        }

        return overlapArr;

    }

    heartBeathandle() {
        if(!this.zoneSettings) return;
        if (this.profiles && this.profiles.length > 1) {
          this.profiles.forEach(profile => {
            if(this.zoneSettings[profile.profile.name] && this.zoneSettings[profile.profile.name]['lastUpdated'] && this.zoneSettings[profile.profile.name]['lastUpdated']['time'] && this.zoneSettings[profile.profile.name]['lastUpdated'].hasOwnProperty('val')) {
              this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(this.zoneSettings[profile.profile.name]['lastUpdated']['time']).local();
            }
          });
        } else {
          if(this.zoneSettings['lastUpdated'] && this.zoneSettings['lastUpdated']['time'] && this.zoneSettings['lastUpdated'].hasOwnProperty('val')) {
            this.zoneSettings['lastUpdated']['val'] = dayjs.utc(this.zoneSettings['lastUpdated']['time']).local();
          }
        }

      }
/**
 * This will check the length of profile and returns a boolean value.
 */
    isModuleOnline(profile) {
        if (this.profiles.length > 1) {
          if(!(this.zoneSettings && this.zoneSettings[profile.profile.name]?.lastUpdated && this.zoneSettings[profile.profile.name]?.lastUpdated.hasOwnProperty('val'))) return;
          this.zoneSettings[profile.profile.name]['lastUpdated']['val'] = dayjs.utc(this.zoneSettings[profile.profile.name]['lastUpdated']['time']).local();
          let timeDiffMinutes = Math.abs(this.zoneSettings[profile.profile.name]['lastUpdated']['val'].diff(dayjs(), 'minutes'))
          return timeDiffMinutes < 16;
        } else {
          if(!(this.zoneSettings && this.zoneSettings['lastUpdated'] && this.zoneSettings['lastUpdated'].hasOwnProperty('val'))) return;
          this.zoneSettings['lastUpdated']['val'] = dayjs.utc(this.zoneSettings['lastUpdated']['time']).local();
          let timeDiffMinutes = Math.abs(this.zoneSettings['lastUpdated']['val'].diff(dayjs(), 'minutes'))
          return timeDiffMinutes < 16;
        }
    }

    ngOnDestroy() {
        document.body.classList.remove('overflow-hidden');
        Object.keys(this.subscriptions).forEach(e => {
            this.subscriptions[e]?.unsubscribe();
        });
        this.siteRef = undefined;
        this.zoneInfo = undefined;
        this.ccuRef = undefined;
        this.vacationsService.updateVacationList = true;
        this.cancelChanges = [];
        this.sliderUserIntentService.clearData();
        this.helperService.clearData();
        this.unsubscribe.next();
        this.unsubscribe.complete();
        //To clear the messaging subscribe[Internally we are calling the api for every 5 sec]
        clearInterval(this.messagingSubscribe);
    }

    UpdateBuildingsDataOnNamedSchApply() {
        this.getZones(this.siteRef);

    }

    openSchedulerModal(schedule) {
        document.body.style.overflow = 'hidden';
        this.openedSchedulerEditModal = true;
        this.schedulerEditDialogRef = this.dialog.open(SchedulerEditModalComponent, {
            panelClass: 'scheduler-occupancy-edit-modal',
            maxHeight: '1000px',
            width: '1050px',
            maxWidth:'1100px',
            autoFocus: false,
            data: {
                zoneName: this.refs.zoneName,
                scheduleId: schedule.id,
                orgName: this.refs?.siteDetails?.organization,
                scheduleInfo: schedule.val,
                buildingLimits: this.buildingLimit,
                defaultLimits: this.defaultLimits,
                sliderDisplayFlag : true,
                roomRef: this.roomObj?._id,
                ccuRef:this.roomObj?.referenceIDs?.ccu,
                fromZonePage: true,
                buildingScheduleInfo: this.zoneSettings?.buildingOccupancy?.val,
                refId : this.refs.roomRef,
                siteId: this.refs.siteRef,
                scheduleGroup: schedule?.scheduleGroup,
                zoneSpecialSchedules: this.zoneSettings.zoneSpecialSchedule,
                unoccupiedSetback:this.zoneSettings?.zoneLevelunoccupiedZoneSetback?.val,
                followBuilding: this.zoneSettings['zoneSchedule']?.followBuilding,
                buildingToZoneDifferential: this.zoneSettings?.buildingToZoneDifferential?.val ? this.userTempPreference == this.unitService.temperatureUnitListArray[0]? this.unitService.convertingDeadBandValue(this.zoneSettings.buildingToZoneDifferential.val) : this.zoneSettings.buildingToZoneDifferential.val : 3,
                globalizationUnit: this.userTempPreference,
                buildingHeatingUserLimitMax: this.zoneSettings?.buildingHeatingUserLimitMax.val,
                buildingCoolingUserLimitMin: this.zoneSettings?.buildingCoolingUserLimitMin.val,
                buildingHeatingUserLimitMin: this.zoneSettings?.buildingHeatingUserLimitMin.val,
                buildingCoolingUserLimitMax: this.zoneSettings?.buildingCoolingUserLimitMax.val,
                buildingHeatingDeadband: this.zoneSettings?.buildingHeatingDeadband.val,
                buildingCoolingDeadband: this.zoneSettings?.buildingCoolingDeadband.val,
                buildingUnoccupiedZoneSetback: this.zoneSettings?.buildingUnoccupiedZoneSetback.val,
                siteTz: this.siteTz,
                enableScheduleGroup: this.enableScheduleGroup,
            }
        });
        this.schedulerEditDialogRef.afterClosed().subscribe(_onClose => {
            document.body.style.overflow = null;
            this.openedSchedulerEditModal = false;
        });
    }

    getEquip(equipTags: any) {
        return this.buildings.equips.filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1) && equip._id == this.ahuRef)[0];
    }
    
    getProfileIcons(zoneId: string): string | null {
        const zoneData = this.allZonesData?.[zoneId];
        switch (zoneData.profileType) {
            case 'emr':
                return 'assets/images/emr.svg';
            case 'modbus':
                return 'assets/images/modbusui.svg';
            case 'pid':
                return 'assets/images/pid.svg';
            default:
                return 'assets/images/temp.svg';
        }
    }
    
    returnRoomObj(zoneName: string) {
        let output = undefined;
        output = this.buildings?.floors?.map(floor => floor.entities.filter(room => { if (room.name === zoneName) { return room; } }));
        output = output.filter((obj) => { if (obj.length != 0) return obj; });
        return output[0];
    }

    checkCertificationAccess(view) {
		return this.authService.isUserAllowedToCheck(view);
	}

    /**
     * Returns the text for the schedule group based on the zone settings.
     * If the zone settings contain a valid schedule group, the corresponding label is returned.
     * Otherwise, an empty string is returned.
     * 
     * @returns The text for the schedule group or an empty string.
     */
    setScheduleGroupText() {
        if (this.zoneSettings?.zoneSchedule?.hasOwnProperty('scheduleGroup')) {
            if (ObjectUtil.isNotUndefinedOrNull(this.zoneSettings?.zoneSchedule?.scheduleGroup)) {
                return this.scheduleGroupLabel[this.zoneSettings?.zoneSchedule?.scheduleGroup];
            }
        }
        return '';
    }

    compareVersions(version1, version2) {
        const v1Parts = version1?.split('.').map(Number);
        const v2Parts = version2?.split('.').map(Number);
    
        for (let i = 0; i < Math.max(v1Parts?.length, v2Parts?.length); i++) {
            const v1 = v1Parts[i] || 0;
            const v2 = v2Parts[i] || 0;
            if (v1 > v2) return 1;
            if (v1 < v2) return -1;
        }
        return 0;
    }

    async getCcuVersion() {
        this.enableScheduleGroup = false;
        const ccuRef = this.roomObj?.referenceIDs?.ccu;
        let ccuEquipRef = this.buildings.ccus.find(c => c._id == ccuRef);
        if (ccuEquipRef) {
            ccuEquipRef = ccuEquipRef.referenceIDs.equip;
            // get ccu version
            const query = `equipRef==@${ccuEquipRef} and (app and diag and version)`;
            const appVerId = await this.siteService.findByQuery(query);
            if (appVerId) {
                appVerId.pipe(
                    map(this.helperService.stripHaystackTypeMapping)).subscribe((res) => {
                        const appVer = res.rows[0]?.id;
                        this.siteService.getWritablePointData(appVer).pipe(
                        map(this.helperService.stripHaystackTypeMapping), takeUntil(this.unsubscribe)
                    ).subscribe(({ rows }) => {
                        if (rows && rows.length > 0) {
                           const version = rows[0].val.toString();
                            this.enableScheduleGroup = this.compareVersions(version, this.compareCcuVersion) > 0;
                        }
                    }, () => {

                    });
                });
            }
        }

    }

    getDiagObj(equipTags: any,ccuEquipRef) {
        return this.buildings.equips
        .filter(equip => equipTags.every(elem => equip.markers.indexOf(elem) > -1) && equip._id == (ccuEquipRef))[0];
    }

    useFeatureToggles() {
        let sub = this.featureToggleService.featureFlagsSubject.subscribe((flags)=>{
            this.setFlags(flags);
            sub.unsubscribe();
            
        });
        this.featureToggleService.getFlags();
    }

    
    setFlags(flags) {
        this.compareCcuVersion = flags.hasOwnProperty('ccu-version') ? flags['ccu-version'].value : '2.4.12';
    }

}
