export const ProfileWidgets = {
    sense: [{
        name: 'th1',
        tags: ['sense', 'th1', 'logical']
    },
    {
        name: 'th2',
        tags: ['sense', 'th2', 'logical']

    }, {
        name: 'analog1',
        tags: ['sense', 'analog1', 'logical']

    }, {
        name: 'analog2',
        tags: ['sense', 'analog2', 'logical']

    }, {
        name: 'currentTemp',
        tags: ['current', 'sense', 'temp']

    }, {
        name: 'co2',
        tags: ['co2', 'sensor', 'sense']

    }, {
        name: 'humidity',
        tags: ['humidity', 'sensor', 'sense']

    }, {
        name: 'voc',
        tags: ['voc', 'sensor', 'sense']

    }, {
        name: 'pm10',
        tags: ['pm10', 'sensor', 'sense']

    }, {
        name: 'pm2p5',
        tags: ['pm2p5', 'sensor', 'sense']

    }, {
        name: 'Humidity',
        tags: ['humidity', 'sensor', 'cur']

    }, {
        name: 'CO',
        tags: ['co', 'sensor', 'cur']

    }, {
        name: 'NO',
        tags: ['no', 'sensor', 'cur']

    }, {
        name: 'Pressure',
        tags: ['pressure', 'sensor', 'cur']

    }, {
        name: 'Occupancy',
        tags: ['occupancy', 'sensor', 'cur']

    }, {
        name: 'sound',
        tags: ['sound', 'sensor', 'cur']

    }, {
        name: 'CO2Equivalent',
        tags: ['co2Equivalent', 'sensor', 'cur']

    }, {
        name: 'Illuminance',
        tags: ['illuminance', 'sensor', 'cur']

    }, {
        name: 'UVI',
        tags: ['uvi', 'sensor', 'cur']

    }
    ],
    monitoring: [{
        name: 'th1',
        tags: ['monitoring', 'th1', 'logical']
    },
    {
        name: 'th2',
        tags: ['monitoring', 'th2', 'logical']

    }, {
        name: 'analog1',
        tags: ['monitoring', 'analog1', 'logical']

    }, {
        name: 'analog2',
        tags: ['monitoring', 'analog2', 'logical']

    }, {
        name: 'currentTemp',
        tags: ['current', 'monitoring', 'temp']

    }, {
        name: 'co2',
        tags: ['co2', 'sensor', 'monitoring']

    }, {
        name: 'humidity',
        tags: ['humidity', 'sensor', 'monitoring']

    }, {
        name: 'voc',
        tags: ['voc', 'sensor', 'monitoring']

    }, {
        name: 'pm10',
        tags: ['pm10', 'sensor', 'monitoring']

    }, {
        name: 'pm2p5',
        tags: ['pm2p5', 'sensor', 'monitoring']

    }, {
        name: 'Humidity',
        tags: ['humidity', 'sensor', 'cur']

    }, {
        name: 'CO',
        tags: ['co', 'sensor', 'cur']

    }, {
        name: 'NO',
        tags: ['no', 'sensor', 'cur']

    }, {
        name: 'Pressure',
        tags: ['pressure', 'sensor', 'cur']

    }, {
        name: 'Occupancy',
        tags: ['occupancy', 'sensor', 'cur']

    }, {
        name: 'sound',
        tags: ['sound', 'sensor', 'cur']

    }, {
        name: 'CO2Equivalent',
        tags: ['co2Equivalent', 'sensor', 'cur']

    }, {
        name: 'Illuminance',
        tags: ['illuminance', 'sensor', 'cur']

    }, {
        name: 'UVI',
        tags: ['uvi', 'sensor', 'cur']

    }, {
        name: 'genericNO',
        tags: ['normallyOpen', 'generic']
    },  {
        name: 'genericNC',
        tags: ['normallyClosed', 'generic']
    }
    ],
    sse: [{
        name: 'targetMinInsideHumidty',
        tags: ['target', 'sse', 'min', 'humidity', 'inside']
    },
    {
        name: 'targetMaxInsideHumidty',
        tags: ['target', 'sse', 'max', 'humidity', 'inside']

    }, {
        name: 'currentTemp',
        tags: ['current', 'sse', 'temp']

    }, {
        name: 'desiredTempHeating',
        tags: ['desired', 'sse', 'temp', 'heating']

    }, {
        name: 'desiredTempCooling',
        tags: ['desired', 'sse', 'temp', 'cooling']

    }, {
        name: 'zoneOccupancy',
        tags: ['zone', 'occupancy', 'mode', 'sse']

    }, {
        name: 'humidity',
        tags: ['humidity', 'sensor', 'sse']

    }, {
        name: 'autoForcedOccupied',
        tags: ['auto', 'forced', 'sse']

    }, {
        name: 'autoAway',
        tags: ['auto', 'away', 'sse', 'config']

    }, {
        name: 'voc',
        tags: ['voc', 'sse', 'air']

    }, {
        name: 'co2',
        tags: ['co2', 'sse', 'air']

    }, {
        name: 'zoneCO2Target',
        tags: ['co2', 'sse', 'target']

    }, {
        name: 'zoneVOCTarget',
        tags: ['voc', 'sse', 'target']

    }, {
        name: 'AirFlowTemp',
        tags: ['temp', 'sse', 'air', 'discharge', 'sensor']

    }, {
        name: 'CO',
        tags: ['co', 'sensor', 'cur']

    }, {
        name: 'NO',
        tags: ['no', 'sensor', 'cur']

    }, {
        name: 'Pressure',
        tags: ['pressure', 'sensor', 'cur']

    }, {
        name: 'Occupancy',
        tags: ['occupancy', 'sensor', 'cur']

    }, {
        name: 'sound',
        tags: ['sound', 'sensor', 'cur']

    }, {
        name: 'CO2Equivalent',
        tags: ['co2Equivalent', 'sensor', 'cur']

    }, {
        name: 'Illuminance',
        tags: ['illuminance', 'sensor', 'cur']

    }, {
        name: 'UVI',
        tags: ['uvi', 'sensor', 'cur']

    }, {
        name: 'scheduleType',
        tags: ['zone', 'sse', 'scheduleType']

    }, {
        name: 'reheatPosition',
        tags: ['reheat', 'sse', 'valve']

    }, {
        name: 'fan',
        tags: ['zone', 'fan', 'sse', 'stage1']

    }, {
        name: 'coolStage1',
        tags: ['zone', 'cooling', 'cmd', 'stage1']

    }, {
        name: 'heatStage1',
        tags: ['zone', 'cmd', 'heating', 'stage1']

    }, {
        name: 'occupancyDetection',
        tags: ['occupancy', 'detection', 'zone']

    }, {
        name: 'currentTx(0-10)',
        tags: ['logical', 'sensor', 'current', 'transformer']

    },
    {
        name: 'currentTx(0-20)',
        tags: ['logical', 'sensor', 'current', 'transformer20']

    },
    {
        name: 'currentTx(0-50)',
        tags: ['logical', 'sensor', 'current', 'transformer50']

    },
    ],
    ti: [{
        name: 'targetMinInsideHumidty',
        tags: ['target', 'ti', 'min', 'humidity', 'inside']
    },
    {
        name: 'targetMaxInsideHumidty',
        tags: ['target', 'ti', 'max', 'humidity', 'inside']

    }, {
        name: 'currentTemp',
        tags: ['current', 'ti', 'temp']

    }, {
        name: 'desiredTempHeating',
        tags: ['desired', 'ti', 'temp', 'heating']

    }, {
        name: 'desiredTempCooling',
        tags: ['desired', 'ti', 'temp', 'cooling']

    }, {
        name: 'zoneOccupancy',
        tags: ['zone', 'occupancy', 'mode', 'ti']

    }, {
        name: 'humidity',
        tags: ['humidity', 'ti']

    }, {
        name: 'voc',
        tags: ['voc', 'ti', 'air']

    }, {
        name: 'co2',
        tags: ['co2', 'ti', 'air']

    }, {
        name: 'zoneCO2Target',
        tags: ['co2', 'ti', 'target']

    }, {
        name: 'zoneVOCTarget',
        tags: ['voc', 'ti', 'target']

    }, {
        name: 'enteringAirTemp',
        tags: ['temp', 'ti', 'air', 'entering', 'sensor']

    }, {
        name: 'dischargeAirTemp',
        tags: ['temp', 'ti', 'air', 'discharge', 'sensor']

    }, {
        name: 'zonePriority',
        tags: ['zone', 'dynamic', 'priority']

    }, {
        name: 'scheduleType',
        tags: ['zone', 'ti', 'scheduleType']

    }, {
        name: 'reheatPosition',
        tags: ['reheat', 'ti', 'valve']

    }, {
        name: 'fanLow',
        tags: ['zone', 'fan', 'ti', 'stage1']

    }, {
        name: 'coolHeatStage1',
        tags: ['zone', 'cooling', 'heating', 'stage1']

    }, {
        name: 'occupancyDetection',
        tags: ['occupancy', 'detection', 'zone']

    }
    ],
    otn: [{
        name: 'targetMinInsideHumidty',
        tags: ['target', 'otn', 'min', 'humidity', 'inside']
    },
    {
        name: 'targetMaxInsideHumidty',
        tags: ['target', 'otn', 'max', 'humidity', 'inside']

    }, {
        name: 'desiredTempCooling',
        tags: ['desired', 'otn', 'temp', 'cooling']

    }, {
        name: 'desiredTempHeating',
        tags: ['desired', 'otn', 'temp', 'heating']

    }, {
        name: 'zoneOccupancy',
        tags: ['zone', 'occupancy', 'mode', 'otn']

    }, {
        name: 'humidity',
        tags: ['humidity', 'otn']

    }, {
        name: 'currentTemp',
        tags: ['current', 'otn', 'temp']

    }, {
        name: 'voc',
        tags: ['voc', 'otn', 'air']

    }, {
        name: 'co2',
        tags: ['co2', 'otn', 'air']

    }, {
        name: 'zoneCO2Target',
        tags: ['co2', 'otn', 'target']

    }, {
        name: 'zoneVOCTarget',
        tags: ['voc', 'otn', 'target']

    }, {
        name: 'enteringAirTemp',
        tags: ['temp', 'otn', 'air', 'entering', 'sensor']

    }, {
        name: 'dischargeAirTemp',
        tags: ['temp', 'otn', 'air', 'discharge', 'sensor']

    }, {
        name: 'CO',
        tags: ['co', 'sensor', 'cur']

    }, {
        name: 'NO',
        tags: ['no', 'sensor', 'cur']

    }, {
        name: 'Pressure',
        tags: ['pressure', 'sensor', 'cur']

    }, {
        name: 'Occupancy',
        tags: ['occupancy', 'sensor', 'cur']

    }, {
        name: 'sound',
        tags: ['sound', 'sensor', 'cur']

    }, {
        name: 'CO2Equivalent',
        tags: ['co2Equivalent', 'sensor', 'cur']

    }, {
        name: 'Illuminance',
        tags: ['illuminance', 'sensor', 'cur']

    }, {
        name: 'UVI',
        tags: ['uvi', 'sensor', 'cur']

    }, {
        name: 'scheduleType',
        tags: ['zone', 'otn', 'scheduleType']

    }, {
        name: 'reheatPosition',
        tags: ['reheat', 'otn', 'valve']

    }, {
        name: 'fanLow',
        tags: ['zone', 'fan', 'otn', 'stage1']

    }, {
        name: 'coolHeatStage1',
        tags: ['zone', 'cooling', 'heating', 'stage1']

    }, {
        name: 'occupancyDetection',
        tags: ['occupancy', 'sensor', 'cur']

    }, {
        name: 'autoForcedOccupied',
        tags: ['otn', 'auto', 'occupied', 'forced']

    }, {
        name: 'autoAway',
        tags: ['otn', 'auto', 'away', 'config']

    }, {
        name: 'zonePriority',
        tags: ['zone', 'dynamic', 'priority']

    }
    ],


    hyperStatHpu: [
        {
            name: 'currentTemp',
            tags: ['current', 'cur', 'temp']
        }, {
            name: 'desiredTempHeating',
            tags: ['desired', 'logical', 'temp', 'heating']
        }, {
            name: 'scheduleType',
            tags: ['zone', 'logical', 'scheduleType']
        }, {
            name: 'desiredTempCooling',
            tags: ['desired', 'logical', 'temp', 'cooling']
        }, {
            name: 'condheatingStage1',
            tags: ['heating', 'cmd', 'stage1']
        }, {
            name: 'condheatingStage2',
            tags: ['heating', 'cmd', 'stage2']
        }, {
            name: 'autoAway',
            tags: ['config', 'auto', 'away', 'enabled']
        }, {
            name: 'autoForcedOccupied',
            tags: ['auto', 'forced', 'occupancy']
        },
        {
            name: 'hpuOperatingMode',
            tags: ['zone', 'hpu', 'operating', 'mode', 'temp']
        },
        {
            name: 'compressorStage1',
            tags: ['zone', 'hpu', 'compressor', 'stage1']
        },
        {
            name: 'compressorStage2',
            tags: ['zone', 'hpu', 'compressor', 'stage2']
        },
        {
            name: 'compressorStage3',
            tags: ['zone', 'hpu', 'compressor', 'stage3']
        },
        {
            name: 'FanModeCpuHpu',
            tags: ['zone', 'hpu', 'fan', 'mode']
        },
        {
            name: 'auxHeatingStage1',
            tags: ['zone', 'electheating', 'aux', 'stage1']
        },
        {
            name: 'auxHeatingStage2',
            tags: ['zone', 'electheating', 'aux', 'stage2']
        },

        {
            name: 'heatpumpChangeoverCooling',
            tags: ['zone', 'hpu', 'changeover', 'cooling']
        },
        {
            name: 'heatpumpChangeoverHeating',
            tags: ['zone', 'hpu', 'changeover', 'heating']
        },
        {
            name: 'modulatingFanSpeedAnalog',

            tags: ['fan', 'logical', 'cmd', 'analog']
        }, {
            name: 'SupplyWaterTemperature',
            tags: ['water', 'logical', 'supply']
        },
        {
            name: 'modulatingDamperAnalog',
            tags: ['damper', 'dcv', 'analog']
        }, {
            name: 'fanLow',
            tags: ['cmd', 'low', 'fan', 'speed', 'logical']
        }, {
            name: 'fanMedium',
            tags: ['cmd', 'medium', 'fan', 'speed', 'logical']
        }, {
            name: 'fanHigh',
            tags: ['cmd', 'high', 'fan', 'speed', 'logical']
        },
        {
            name: 'ModulatingCompressorSpeed',
            tags: ['compressor', 'speed', 'analog', 'logical']
        },
        {
            name: 'doorSensorTh2',
            tags: ['contact', 'door', 'window']
        }, {
            name: 'currentTx(0-10)',
            tags: ['logical', 'sensor', 'current', 'transformer']
        }, {
            name: 'currentTx(0-20)',
            tags: ['logical', 'sensor', 'current', 'transformer20']
        },
        {
            name: 'currentTx(0-50)',
            tags: ['logical', 'sensor', 'current', 'transformer50']
        },
        {
            name: 'analog1InKeyCardSensor',
            tags: ['logical', 'sensor', 'keycard']
        }, {
            name: 'analog1InDoorSensor1',
            tags: ['logical', 'sensor', 'door', 'window2']
        }, {
            name: 'analog2InKeyCardSensor',
            tags: ['logical', 'sensor', 'keycard2']
        }, {
            name: 'analog2InDoorSensor2',
            tags: ['logical', 'sensor', 'door', 'window3']
        }, {
            name: 'airflowTempSensorTh1',
            tags: ['air', 'sensor', 'temp', 'discharge']
        }, {
            name: 'SupplyWaterTemperature',
            tags: ['supply', 'water', 'cur', 'temp']
        }, {
            name: 'FanModeHsCpuHpu',
            tags: ['zone', 'sp', 'operation', 'fan', 'mode']
        }, {
            name: 'ZoneOperationalMode',
            tags: ['operating', 'point', 'enum']
        }, {
            name: 'zoneOccupancy',
            tags: ['zone', 'occupancy', 'mode', 'enum']
        }, {
            name: 'occupancyDetection',
            tags: ['occupancy', 'zone', 'sensor', 'logical']
        }, {
            name: 'Humidity',
            tags: ['humidity', 'sensor', 'cur']
        }, {
            name: 'CO2',
            tags: ['co2', 'sensor', 'cur']
        }, {
            name: 'CO',
            tags: ['co', 'sensor', 'cur']
        }, {
            name: 'NO',
            tags: ['no', 'sensor', 'cur']
        }, {
            name: 'VOC',
            tags: ['voc', 'sensor', 'cur']
        }, {
            name: 'Pressure',
            tags: ['pressure', 'sensor', 'cur']
        }, {
            name: 'Occupancy',
            tags: ['occupancy', 'sensor', 'cur']
        }, {
            name: 'sound',
            tags: ['sound', 'sensor', 'cur']
        }, {
            name: 'Illuminance',
            tags: ['illuminance', 'sensor', 'cur']
        }, {
            name: 'UVI',
            tags: ['uvi', 'sensor', 'cur']
        }, {
            name: 'pm10',
            tags: ['pm10', 'sensor']
        }, {
            name: 'pm2p5',
            tags: ['pm2p5', 'sensor']
        },
        /**currently we are not plotting as per widget sheet in future we may use **/
        // {
        //     name: 'humidifier',
        //     tags: ['control', 'humidifier', 'target', 'his', 'hisInterpolate']
        // }, {
        //     name: 'dehumidifier',
        //     tags: ['control', 'dehumidifier', 'target', 'his', 'hisInterpolate']
        // }, 

        {
            name: 'ZoneConditionMode',
            tags: ['zone', 'conditioning', 'mode', 'sp']
        },
        {
            name:'genericNO',
            tags:['normallyOpen','generic']
        },
        {
            name:'genericNC',
            tags:['normallyClosed','generic']
        }
    ],



    hyperStatPipe2: [
        {
            name: 'currentTemp',
            tags: ['current', 'cur', 'temp']
        },
        {
            name: 'desiredTempHeating',
            tags: ['desired', 'logical', 'temp', 'heating']
        },
        {
            name: 'scheduleType',
            tags: ['zone', 'logical', 'scheduleType']
        },
        {
            name: 'desiredTempCooling',
            tags: ['desired', 'logical', 'temp', 'cooling']
        },
        {
            name: 'condheatingStage1',
            tags: ['heating', 'cmd', 'stage1']
        },
        {
            name: 'condheatingStage2',
            tags: ['heating', 'cmd', 'stage2']
        },
        {
            name: 'autoAway',
            tags: ['config', 'auto', 'away', 'enabled']
        },
        {
            name: 'autoForcedOccupied',
            tags: ['auto', 'forced', 'occupancy']
        },
        {
            name: 'modulatingWaterValveAnalog',
            tags: ['cooling', 'water', 'analog', 'logical']
        },
        {
            name: 'waterValve',
            tags: ['water', 'valve', 'cooling', 'heating', 'logical', 'relay']
        },
        {
            name: 'modulatingFanSpeedAnalog',
            tags: ['fan', 'logical', 'cmd', 'analog']
        },
        {
            name: 'SupplyWaterTemperature',
            tags: ['water', 'logical', 'supply']
        },
        {
            name: 'modulatingDamperAnalog',
            tags: ['damper', 'dcv', 'analog']
        },
        {
            name: 'fanLow',
            tags: ['cmd', 'low', 'fan', 'speed', 'logical']
        },
        {
            name: 'fanMedium',
            tags: ['cmd', 'medium', 'fan', 'speed', 'logical']
        },
        {
            name: 'fanHigh',
            tags: ['cmd', 'high', 'fan', 'speed', 'logical']
        },
        {
            name: 'doorSensorTh2',
            tags: ['contact', 'door', 'window']
        },
        {
            name: 'analog1InKeyCardSensor',
            tags: ['logical', 'sensor', 'keycard']
        },
        {
            name: 'analog1InDoorSensor1',
            tags: ['logical', 'sensor', 'door', 'window2']
        },
        {
            name: 'currentTx(0-10)',
            tags: ['logical', 'sensor', 'current', 'transformer']
        }, {
            name: 'currentTx(0-20)',
            tags: ['logical', 'sensor', 'current', 'transformer20']
        },
        {
            name: 'currentTx(0-50)',
            tags: ['logical', 'sensor', 'current', 'transformer50']
        },
        {
            name: 'analog2InKeyCardSensor',
            tags: ['logical', 'sensor', 'keycard2']
        },
        {
            name: 'analog2InDoorSensor2',
            tags: ['logical', 'sensor', 'door', 'window3']
        },
        {
            name: 'airflowTempSensorTh1',
            tags: ['air', 'sensor', 'temp', 'discharge']
        },
        {
            name: 'SupplyWaterTemperature',
            tags: ['supply', 'water', 'cur', 'temp']
        },
        {
            name: 'FanModeHsCpuHpu',
            tags: ['zone', 'sp', 'operation', 'fan', 'mode']
        },
        {
            name: 'ZoneOperationalMode',
            tags: ['operating', 'point', 'enum']
        },
        {
            name: 'zoneOccupancy',
            tags: ['zone', 'occupancy', 'mode', 'enum']
        },
        {
            name: 'occupancyDetection',
            tags: ['occupancy', 'sensor', 'zone', 'logical']
        },
        {
            name: 'Humidity',
            tags: ['humidity', 'sensor', 'cur']
        },
        {
            name: 'CO2',
            tags: ['co2', 'sensor', 'cur']
        },
        {
            name: 'CO',
            tags: ['co', 'sensor', 'cur']
        },
        {
            name: 'NO',
            tags: ['no', 'sensor', 'cur']
        },
        {
            name: 'VOC',
            tags: ['voc', 'sensor', 'cur']
        },
        {
            name: 'Pressure',
            tags: ['pressure', 'sensor', 'cur']
        },
        {
            name: 'Occupancy',
            tags: ['occupancy', 'sensor', 'cur']
        },
        {
            name: 'sound',
            tags: ['sound', 'sensor', 'cur']
        },
        {
            name: 'Illuminance',
            tags: ['illuminance', 'sensor', 'cur']
        },
        {
            name: 'UVI',
            tags: ['uvi', 'sensor', 'cur']
        },
        {
            name: 'pm10',
            tags: ['pm10', 'sensor']
        },
        {
            name: 'pm2p5',
            tags: ['pm2p5', 'sensor']
        },
        /**currently we are not plotting as per widget sheet in future we may use **/
        // {
        //     name: 'humidifier',
        //     tags: ['control', 'humidifier', 'target', 'his', 'hisInterpolate']
        // }, {
        //     name: 'dehumidifier',
        //     tags: ['control', 'dehumidifier', 'target', 'his', 'hisInterpolate']
        // }, 
        {
            name: 'ZoneConditionMode',
            tags: ['zone', 'conditioning', 'mode', 'sp']
        },
        {
            name:'genericNO',
            tags:['normallyOpen','generic']
        },
        {
            name:'genericNC',
            tags:['normallyClosed','generic']
        }
    ],

    bpos: [{
        name: 'targetMinInsideHumidty',
        tags: ['target', 'bpos', 'min', 'humidity', 'inside']
    },
    {
        name: 'targetMaxInsideHumidty',
        tags: ['target', 'bpos', 'max', 'humidity', 'inside']

    }, {
        name: 'desiredTempCooling',
        tags: ['desired', 'bpos', 'temp', 'cooling']

    }, {
        name: 'zoneOccupancy',
        tags: ['zone', 'occupancy', 'mode', 'bpos']

    }, {
        name: 'humidity',
        tags: ['humidity', 'bpos']

    }, {
        name: 'currentTemp',
        tags: ['current', 'bpos', 'temp']

    }, {
        name: 'voc',
        tags: ['voc', 'bpos', 'air']

    }, {
        name: 'co2',
        tags: ['co2', 'bpos', 'air']

    }, {
        name: 'zoneCO2Target',
        tags: ['co2', 'bpos', 'target']

    }, {
        name: 'zoneVOCTarget',
        tags: ['voc', 'bpos', 'target']

    }, {
        name: 'enteringAirTemp',
        tags: ['temp', 'bpos', 'air', 'entering', 'sensor']

    }, {
        name: 'dischargeAirTemp',
        tags: ['temp', 'bpos', 'air', 'discharge', 'sensor']

    }, {
        name: 'CO',
        tags: ['co', 'sensor', 'cur']

    }, {
        name: 'NO',
        tags: ['no', 'sensor', 'cur']

    }, {
        name: 'Pressure',
        tags: ['pressure', 'sensor', 'cur']

    }, {
        name: 'Occupancy',
        tags: ['occupancy', 'sensor', 'cur']

    }, {
        name: 'sound',
        tags: ['sound', 'sensor', 'cur']

    }, {
        name: 'CO2Equivalent',
        tags: ['co2Equivalent', 'sensor', 'cur']

    }, {
        name: 'Illuminance',
        tags: ['illuminance', 'sensor', 'cur']

    }, {
        name: 'UVI',
        tags: ['uvi', 'sensor', 'cur']

    }, {
        name: 'scheduleType',
        tags: ['zone', 'bpos', 'scheduleType']

    }, {
        name: 'reheatPosition',
        tags: ['reheat', 'bpos', 'valve']

    }, {
        name: 'fanLow',
        tags: ['zone', 'fan', 'bpos', 'stage1']

    }, {
        name: 'coolHeatStage1',
        tags: ['zone', 'cooling', 'heating', 'stage1']

    }, {
        name: 'occupancyDetection',
        tags: ['occupancy', 'sensor', 'cur']

    }, {
        name: 'autoForcedOccupied',
        tags: ['bpos', 'auto', 'occupied', 'forced']

    }, {
        name: 'autoAway',
        tags: ['bpos', 'auto', 'away', 'config']

    }, {
        name: 'zonePriority',
        tags: ['zone', 'dynamic', 'priority']
    }
    ],

    hstat_split_cpu: [{
        name: 'currentTx(0-10)',
        tags: ['current', 'transformer'],
        domainName:'currentTx10'
    }, {
        name: 'currentTx(0-20)',
        tags: ['current', 'transformer20'],
        domainName:'currentTx20'
    }, {
        name: 'currentTx(0-50)',
        tags: ['current', 'transformer50'],
        domainName:'currentTx50'
    }, 
    //New points for dm
    {
        name: 'currentTx(0-30)',
        domainName:'currentTx30'
    },{
        name: 'currentTx(0-60)',
        domainName:'currentTx60'
    },{
        name: 'currentTx(0-100)',
        domainName:'currentTx100'
    },{
        name: 'currentTx(0-120)',
        domainName:'currentTx120'
    },{
        name: 'currentTx(0-150)',
        domainName:'currentTx150'
    },{
        name: 'currentTx(0-200)',
        domainName:'currentTx200'
    },
    {
        name: 'currentTemp',
        tags: ['current', 'cur', 'temp'],
        domainName:'currentTemp'

    }, {
        name: 'desiredTempHeating',
        tags: ['desired', 'logical', 'temp', 'heating'],
        domainName:'desiredTempHeating'
    }, {
        name: 'desiredTempCooling',
        tags: ['desired', 'logical', 'temp', 'cooling'],
        domainName:'desiredTempCooling'
    }, {
        name: 'condheatingStage1',
        tags: ['heating', 'cmd', 'stage1'],
        domainName:'heatingStage1'
    }, {
        name: 'condheatingStage2',
        tags: ['heating', 'cmd', 'stage2'],
        domainName:'heatingStage2'
    }, {
        name: 'condheatingStage3',
        tags: ['heating', 'cmd', 'stage3'],
        domainName:'heatingStage3'
    }, {
        name: 'condheatingStage4',
        tags: ['heating', 'cmd', 'stage4'],
        domainName:'heatingStage4'
    },{
        name: 'condcoolingStage1',
        tags: ['cooling', 'cmd', 'stage1'],
        domainName:'coolingStage1'
    }, {
        name: 'condcoolingStage2',
        tags: ['cooling', 'cmd', 'stage2'],
        domainName:'coolingStage2'
    }, {
        name: 'condcoolingStage3',
        tags: ['cooling', 'cmd', 'stage3'],
        domainName:'coolingStage3'
    },{
        name: 'condcoolingStage4',
        tags: ['cooling', 'cmd', 'stage4'],
        domainName:'coolingStage4'
    }, {
        name: 'autoAway',
        tags: ['config', 'auto', 'away', 'enabled'],
        domainName:'autoAway'
    }, {
        name: 'autoForcedOccupied',
        tags: ['auto', 'forced', 'occupancy'],
        domainName:'autoForceOccupied'
    }, {
        name: 'modulatingCoolingAnalog1',
        tags: ['cooling', 'modulating', 'cmd', 'analog', 'logical'],
        domainName: 'coolingSignal'
    }, {
        name: 'modulatingHeatingAnalog1',
        tags: ['heating', 'modulating', 'cmd', 'analog', 'logical', 'elecHeating'],
        domainName: 'heatingSignal'
    }, {
        name: 'fanLow',
        tags: ['cmd', 'low', 'fan', 'speed', 'logical'],
        domainName: 'fanLowSpeed'
    }, {
        name: 'fanMedium',
        tags: ['cmd', 'medium', 'fan', 'speed', 'logical'],
        domainName: 'fanMediumSpeed'
    }, {
        name: 'fanHigh',
        tags: ['cmd', 'high', 'fan', 'speed', 'logical'],
        domainName: 'fanHighSpeed'
    }, {
        name: 'doorSensorTh2',
        tags: ['door', 'sensor', 'window', 'contact']
    }, {
        name: 'analog1InCurrent',
        tags: ['logical', 'sensor', 'current', 'maxVal']
    }, {
        name: 'analog1InKeyCardSensor',
        tags: ['logical', 'sensor', 'keycard']
    },{
        name: 'analog1InDoorSensor1',
        tags: ['logical', 'sensor', 'door','window2']
    }, {
        name: 'analog2InCurrent',
        tags: ['logical', 'sensor', 'current', 'maxVal']
    }, {
        name: 'analog2InKeyCardSensor',
        tags: ['logical', 'sensor', 'keycard2']
    }, {
        name: 'analog2InDoorSensor2',
        tags: ['logical', 'sensor', 'door', 'window3']
    }, {
        name: 'airflowTempSensorTh1',
        tags: ['air', 'sensor', 'temp', 'discharge'],
        domainName:'supplyAirTempSensor'
    }, {
        name: 'FanModeHsCpuHpu',
        tags: ['zone', 'sp', 'operation', 'fan', 'mode'],
        domainName:'fanOpMode'
    }, {
        name: 'ZoneOperationalMode',
        tags: ['operating', 'mode', 'enum'],
        domainName:'operatingMode'
    }, {
        name: 'ZoneConditionMode',
        tags: ['zone', 'conditioning', 'mode', 'sp'],
        domainName:'conditioningMode'
    }, {
        name: 'occupancyDetection',
        tags: ['occupancy', 'zone', 'sensor', 'logical'],
        domainName:'zoneOccupancy'
    }, {
        name: 'zoneOccupancy',
        tags: ['zone', 'occupancy', 'mode', 'enum'],
        domainName:'occupancyMode'
    }, {
        name: 'Humidity',
        tags: ['humidity', 'sensor', 'cur'],
        domainName:'zoneHumidity'
    }, {
        name: 'CO2',
        tags: ['co2', 'sensor', 'cur'],
        domainName:'zoneCO2'
    }, {
        name: 'CO',
        tags: ['co', 'sensor', 'cur'],
        domainName:'zoneCO'
    }, {
        name: 'NO',
        tags: ['no', 'sensor', 'cur'],
        domainName:'zoneNO'
    }, {
        name: 'VOC',
        tags: ['voc', 'sensor', 'cur'],
        domainName:'zoneVOC'
    }, {
        name: 'Pressure',
        tags: ['pressure', 'sensor', 'cur'],

    }, {
        name: 'Occupancy',
        tags: ['occupancy', 'sensor', 'cur'],
        domainName:'zoneOccupancy'
    }, {
        name: 'sound',
        tags: ['sound', 'sensor', 'cur'],
        domainName:'zoneSound'
    }, {
        name: 'Illuminance',
        tags: ['illuminance', 'sensor', 'cur'],
        domainName:'zoneIlluminance'
    }, {
        name: 'UVI',
        tags: ['uvi', 'sensor', 'cur']
    }, {
        name: 'pm10',
        tags: ['pm10', 'sensor']
    }, {
        name: 'pm2p5',
        tags: ['pm2p5', 'sensor'],
        domainName:'zonePm25'
    }, {
        name: 'linearFanSpeed',
        tags: ['fan', 'speed', 'modulating','zone'],
        domainName:'linearFanSpeed'
    }, {
        name: 'predefinedFanSpeed',
        tags: ['fan', 'speed', 'predefined','zone'],
        domainName:'stagedFanSpeed'
    }, {
        name: 'maxAirTemp',
        tags: ['economizing', 'max', 'temp', 'oao'],
        domainName:'standaloneEconomizingMaxTemperature'
    }, {
        name: 'minAirTemp',
        tags: ['economizing', 'min', 'temp', 'oao'],
        domainName:'standaloneEconomizingMinTemperature'
    }, {
        name: 'supplyAirTemp',
        tags: ['sensor','discharge', 'temp'],
        domainName:'dischargeAirTemperature'
    }, {
        name: 'mixedAirFlowTemp',
        tags: ['sensor', 'mixed', 'temp'],
        domainName:'mixedAirTemperature'
    }, {
        name: 'outsideAirDamperPosition',
        tags: ['damper','output','economizer'],
        domainName:'oaoDamper'
    }, {
        name: 'returnDamperPosition',
        tags: ['return', 'air', 'damper'],
        domainName:'returnDamperPosition'
    }, {
        name: 'CO2Threshold',
        tags: ['co2', 'threshold', 'config', 'cpu'],
        domainName:'co2Threshold'
    }, {
        name: 'returnAirCO2',
        tags: ['co2', 'return', 'air']
    }, {
        name: 'co2DamperOpeningRate',
        tags: ['damper', 'opening', 'rate', 'cpu', 'co2'],
        domainName:'co2DamperOpeningRate'
    }, {
        name: 'usePerRoomCo2Sensing',
        tags: ['co2', 'config', 'sensing']
    }, {
        name: 'exhaustFanStage1',
        tags: ['exhaust', 'fan', 'stage1', 'cmd'],
        domainName:'exhaustFanStage1'
    }, {
        name: 'exhaustFanStage2',
        tags: ['exhaust', 'fan', 'stage2', 'cmd'],
        domainName:'exhaustFanStage2'
    }, {
        name: 'co2WeightedAverage',
        tags: ['co2', 'weighted', 'average'],
        domainName:'co2WeightedAverage'
    }, {
        name: 'outsideEnthalpy',
        tags: ['outside', 'enthalpy', 'sp', 'cpu'],
        domainName:'outsideEnthalpy'
    }, {
        name: 'insideEnthalpy',
        tags: ['inside', 'enthalpy', 'sp', 'cpu'],
        domainName:'insideEnthalpy'
    }, {
        name: 'freeCoolingEconomize',
        tags: ['economizing', 'available', 'oao', 'cpu'],
        domainName:'economizingAvailable'
    }, {
        name: 'freeCoolingDCV',
        tags: ['dcv', 'available', 'oao', 'cpu'],
        domainName:'dcvAvailable'
    }, {
        name: 'freeCoolingMat',
        tags: ['mat', 'available', 'oao', 'cpu'],
        domainName:'matThrottle'
    }, {
        name: 'weatherOutSideTemp',
        tags: ['outsideWeather','oao','temp','sensor'],
        domainName:'weatherOutsideTemp'
    }, {
        name: 'weatherOutSideHumidity',
        tags: ['outsideWeather','oao','humidity','sensor'],
        domainName:'weatherOutsideHumidity'
    }, {
        name:'ductStaticPressure',
        tags:['pressure','static','sensor','zone'],
        domainName:'ductStaticPressureSensor'
    }, {
        name: 'supplyAirHumiditySensor',
        tags:['discharge','air','humidity','sensor'],
        domainName:'dischargeAirHumiditySensor'

    }, {
        name:'filterNO',
        tags:['filter','normallyOpen','sensor','zone'],
        domainName:'filterStatusNO'
    },{
        name:'filterNC',
        tags:['filter','normallyClosed','sensor','zone'],
        domainName:'filterStatusNC'
    },{
        name: 'condensateNO',
        tags:['condensate','normallyOpen','sensor','zone'],
        domainName:'condensateStatusNO'
    },{
        name: 'condensateNC',
        tags:['condensate','normallyClosed','sensor','zone'],
        domainName:'condensateStatusNC'
    },{
        name:'pressure0-2',
        tags:['pressure2in','sensor','pressure','zone'],
        domainName:'ductStaticPressureSensor1_2'
    },{
        name:'pressure0-1',
        tags:['pressure1in','sensor','pressure','zone'],
        domainName:'ductStaticPressureSensor1_1'
    },{
        name:'pressure0-10',
        tags:['pressure10in','sensor','pressure','zone'],
        domainName:'ductStaticPressureSensor1_10'
    },{
        name:'genericResistance8',
        tags:['generic','sensor','resistance','zone'],
        domainName:'thermistorInput'
    },{
        name:'genericVoltage8',
        tags:['generic','sensor','volt','zone'],
        domainName:'voltageInput'
    }, {
        name:'outsideAirTempSensor',
        tags:['sensor','outside','air','temp'],
        domainName:'outsideTemperature',
    },  {
        name:'genericNO',
        tags:['normallyOpen','generic'],
        domainName:'genericAlarmNO'
    },  {
        name:'genericNC',
        tags:['normallyClosed','generic'],
        domainName:'genericAlarmNC'
    } ,{
        name:'prepurgestatus',
        tags:['prePurge','run','sensor'],
        domainName:'prePurgeStatus'
    }

    ],

    vav_terminal : {
        widgets: [{
            name: 'humidity',
            tags: ['humidity', 'vav'] ,
            domainName: 'zoneHumidity'
        }, 
        {
            name: 'desiredTempHeating',
            tags: ['desired', 'vav', 'temp', 'heating'] ,
            domainName: 'desiredTempHeating' 
        },
        {
            name: 'targetMinInsideHumidty',
            tags: ['target', 'vav', 'min', 'humidity', 'inside'] ,
            domainName:''
        },
        {
            name: 'targetMaxInsideHumidty',
            tags: ['target', 'vav', 'max', 'humidity', 'inside'] ,
            domainName:''
        },
        {
            name: 'zoneOccupancy',
            tags: ['zone', 'occupancy', 'mode', 'vav'] ,
            domainName: 'occupancyMode'
        },
        {
            name: 'desiredTempCooling',
            tags: ['desired', 'vav', 'temp', 'cooling'] ,
            domainName: 'desiredTempCooling'
        },
        {
            name: 'co2',
            tags: ['co2', 'vav', 'air'] ,
            domainName: 'zoneCO2'   
        },
        {
            name: 'voc',
            tags: ['voc', 'vav', 'air'] ,
            domainName: 'zoneVOC'   
        },
        {
            name: 'zoneCO2Target',
            tags: ['co2', 'target', 'vav'] ,
            domainName: 'vavZoneCo2Target'   
        },
        {
            name: 'zoneVOCTarget',
            tags: ['voc', 'target', 'vav'] ,
            domainName: 'vavZoneVocTarget'   
        },
        {
            name: 'maxCoolingDamperPos',
            tags: ['max', 'vav', 'cooling', 'damper', 'pos'] ,
            domainName: 'maxCoolingDamperPos'   
        },
        {
            name: 'enteringAirTemp',
            tags: ['temp', 'vav', 'air', 'entering', 'sensor'] ,
            domainName: 'enteringAirTemp'   
        },
        {
            name: 'scheduleType',
            tags: ['zone', 'vav', 'scheduleType'] ,
            domainName: 'scheduleType'   
        },
        {
            name: 'dischargeAirTemp',
            tags: ['temp', 'vav', 'air', 'discharge', 'sensor'] ,
            domainName: 'dischargeAirTemp'   
        },
        {
            name: 'maxHeatingDamperPos',
            tags: ['max', 'vav', 'heating', 'damper', 'pos'] ,
            domainName: 'maxHeatingDamperPos'   
        },
        {
            name: 'minCoolingDamperPos',
            tags: ['min', 'vav', 'cooling', 'damper', 'pos'] ,
            domainName: 'minCoolingDamperPos'   
        },
        {
            name: 'minHeatingDamperPos',
            tags: ['min', 'vav', 'heating', 'damper', 'pos'] ,
            domainName: 'minHeatingDamperPos'   
        },
        {
            name: 'occupancyDetection',
            tags: ['occupancy', 'detection', 'zone'] ,
            domainName: 'occupancyDetection'   
        },
        {
            name: 'damperPos',
            tags: ['damper', 'vav', 'normalized'] ,
            domainName: 'normalizedDamperCmd'   
        },
        {
            name: 'reheatPosition',
            tags: ['reheat', 'vav', 'valve'] ,
            domainName: 'reheatCmd'   
        },
        {
            name: 'zonePriority',
            tags: ['zone', 'dynamic', 'priority'] ,
            domainName: 'zoneDynamicPriority'   
        },
        {
            name: 'cfmAirFlowVolume',
            tags: ['air', 'flow', 'trueCfm'] ,
            domainName: 'airFlowSensor'   
        },
        {
            name: 'cfmMinHeating',
            tags: ['heating', 'min', 'trueCfm'] ,
            domainName: 'minCFMReheating'   
        },
        {
            name: 'cfmMaxHeating',
            tags: ['heating', 'max', 'trueCfm'] ,
            domainName: 'maxCFMReheating'   
        },
        {
            name: 'cfmMinCooling',
            tags: ['cooling', 'min', 'trueCfm'] ,
            domainName: 'minCFMCooling'   
        },
        {
            name: 'cfmMaxCooling',
            tags: ['cooling', 'max', 'trueCfm'] ,
            domainName: 'maxCFMCooling' 
        },
        {
            name: 'autoAway',
            tags: ['vav', 'auto', 'away', 'config'] ,
            domainName: 'autoAway'   
        },
        {
            name: 'autoForcedOccupied',
            tags: ['vav', 'auto', 'forced'] ,
            domainName: 'autoForceOccupied'   
        },
        {
            name: 'Occupancy',
            tags: ['occupancy', 'sensor', 'cur'] ,
            domainName: ''   
        },
        {
            name: 'CO',
            tags: ['co', 'sensor', 'cur'] ,
            domainName: ''   
        },
        {
            name: 'NO',
            tags: ['no', 'sensor', 'cur'] ,
            domainName: ''   
        },
        {
            name:'UVI',
            tags: ['uvi', 'sensor', 'cur'] ,
            domainName: '' 
        },
        {
            name:'CO2Equivalent',
            tags: ['co2Equivalent', 'sensor', 'cur'] ,
            domainName: '' 
        },
        {
            name:'Pressure',
            tags: ['pressure', 'sensor', 'cur'] ,
            domainName: 'pressureSensor' 
        },
        {
            name:'sound',
            tags: ['sound', 'sensor', 'cur'] ,
            domainName: '' 
        },
        {
            name:'Illuminance',
            tags: ['illuminance', 'sensor', 'cur'] ,
            domainName: '' 
        },
        {
            name:'seriesFan',
            tags: ['series','fan'],
            domainName:'seriesFanCmd'
        },
        {
            name:'parallelFan',
            tags:['parallel','fan'],
            domainName:'parallelFanCmd'
        },{
            name: 'airFlowSetpoint',
            domainName: 'airFlowSetpoint'
        },{
            name: 'dischargeAirTempSetpoint',
            domainName: 'dischargeAirTempSetpoint'
        }, {
            name: 'calculativeDamper',
            domainName: 'damperCmdCal',
        }, {
            name: 'calculativeReheat',
            domainName:'reheatCmdCal',
        }
        ]
    }
}






