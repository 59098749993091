import { ConfigurationService, HelperService } from '@75f/portal-ui-components';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'fs-custom-dashboard',
  templateUrl: './custom-dashboard.component.html',
  styleUrls: ['./custom-dashboard.component.scss']
})
export class CustomDashboardComponent implements OnInit {

  urlSafe: SafeResourceUrl;
  isLoading: boolean = true;
  seletedSite;

  constructor(private configService: ConfigurationService,
    private sanitizer: DomSanitizer,  private helperService: HelperService,
    @Inject('LOCALSTORAGE') private localStorage: any) {}

  ngOnInit(): void {
    const appRoute = 'facilisight';
    const token = this.localStorage.getItem('bearer_token');
    const userData = JSON.parse(this.localStorage.getItem('user_data'));
    const userPrefLangauge = this.helperService.getLanguagePrefToggle() ? this.helperService.getUserPreferredLanguage() : 'en';
    let customdashboardUrl = this.configService.getConfig('customDashboardUrl');
    let urlSafe = `${customdashboardUrl}?portal=${appRoute}&token=${token}&preferredLanguage=${userPrefLangauge}&userId=${userData?.userId}`;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(urlSafe);
  }

}
