import {
  AlertService, HeatmapFilterService, CustomHeatmapService, HeatmapConnectService, ArrayUtil,
  ObjectUtil, SortOrderComponent, UserService, SiteService, HelperService, UnitService, AuthenticationService,HeatmapService,NotesService
} from '@75f/portal-ui-components';
import {
  Component,
  Input,
  HostListener,
  Output,
  OnInit,
  EventEmitter,
  SimpleChange,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  ElementRef
} from '@angular/core';

import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { uniq } from 'lodash-es';
import sortBy from 'lodash-es/sortBy';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

@Component({
  selector: 'fs-heatmap-header',
  styleUrls: ['./heatmap-header.component.scss'],
  templateUrl: './heatmap-header.component.html',
  encapsulation: ViewEncapsulation.None
})
export class HeatmapHeaderComponent implements OnInit {
  @Input() floors: any = [];
  @Input() checkForEventType:any;
  @Input() ccusWithPairedZones: Array<any> = [];
  @Input() ccus: any = [];
  @Input() zones: any = [];
  @Input() deadbandScale: any = [];
  @Input() highlightCCU;
  @Input() siteId;
  @Input() selectedFilter;
  @Input() hideCCUHeader: boolean = false;
  @Input() showQRCode;
  @Output() onChangeFloor: any = new EventEmitter();
  @Output() onChangeCCU: any = new EventEmitter();
  @Output() onChangeZone: any = new EventEmitter();
  @Output() onSeachInputChange: any = new EventEmitter();
  @Output() onHoverCcu: any = new EventEmitter();
  @Output() onLeaveCcu: any = new EventEmitter();
  @Output() onClickCcu: any = new EventEmitter();
  @Output() autoRefreshChange = new EventEmitter();
  @Output() filterChange = new EventEmitter();
  @Output() scrollAndExpandEvent = new EventEmitter();
  @Output() defaultFilterChange = new EventEmitter(); 
  @ViewChild(MatAutocompleteTrigger, { static: false }) trigger: MatAutocompleteTrigger;
  @Output() ccuOrderChange = new EventEmitter();
  @ViewChild('floorListRef') floorRef: ElementRef;
  @ViewChild('ccuListRef') ccuRef: ElementRef;
  @ViewChild('zoneListRef') zoneRef: ElementRef;
  @Input() defaultFilter;
  onclickScrollValue:any;
  onclickScrollValue2:any;
  previousVal:any;
  preveSelectedCCU = 'all';
  prevSelectedFloor = 'all';
  selectedCCU :any = [];
  selectedFloor : any = [];
  zonesSearch = '';
  filteredOptions = [];
  selectedOption;
  searchTextChanged = new Subject();
  autoRefreshSelection = 6;
  expandCcu:boolean = true;
  hideQRCode: boolean = false;
  hideCcuHeaderIcon:boolean;
  subscriptions: any = {};
  dFTStatusObject: any = {
    showDFT: false,
    changesCount: 0
  };
  ccusOptions = [];
  siteName;
  tagList: any = [];
  allFloor: any = [];
  checkForFloor: boolean = false
  selectedZones: any =[];
  floorsSelected: boolean;
  ccuuSelectedArray: any = [];
  ccusOptionsSelected: any = [];
  zonesBasedonCCU: any = [];
  selectedCcusFloor: any = [];
  orientationangle = 0;
  showOrientationCompass: boolean;
  ignoreClasses = ['icon_style'];
  floorSelectionExist: boolean = false;
  noFloorSelected: boolean = false;
  ccuSelected: boolean = false;
  compareFloorChanges: any = [];
  compareCCUChanges: any = [];
  compareZoneChanges: any = [];
  scroll:any;
  floorSelectionMade: boolean = false;
  notesList = [];
  handleStorageEvent;

  @HostListener('window: scroll', ['$event'])
    onWindowScroll($event) {
      this.onclickScrollValue2 = window.scrollY
      setTimeout(() => {
        if(!this.checkForEventType && this.hideCcuHeaderIcon) {
          if(this.onclickScrollValue2 > this.onclickScrollValue) {
            this.expandCcu = false;
        }
        }
      }, 200);
  }
  
    @HostListener('click') onClick() { 
      this.scroll = window.scrollY
      this.onclickScrollValue = window.scrollY
    }
  
  constructor(public heatmapConnect: HeatmapConnectService,
    public dialog: MatDialog, private heatmapSrvc: CustomHeatmapService,
    public userPref: UserService,
    public alertService: AlertService,
    public siteService: SiteService,
    public hsHelper: HelperService,
    private unitService: UnitService,
    public filterSrvc: HeatmapFilterService,
    public authService: AuthenticationService,
    public notesService : NotesService,
    private heatmapService:HeatmapService) 
     {
    this.searchTextChanged.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((search) => {
      if (typeof search == 'string')
        this.filterZones();
    });
    this.handleStorageEvent = this.onStorageEvent.bind(this);
    window.addEventListener('storage', this.handleStorageEvent);
  }

  onStorageEvent(event: StorageEvent) {
    if (event.key === 'selectedFilter') {
      this.selectedFilter = ObjectUtil.parseJSON(localStorage.getItem('selectedFilter'));
      this.checkSelectedFilter();
      this.filterChange.emit(this.selectedFilter);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const floors: SimpleChange = changes.floors;
    const ccus: SimpleChange = changes.ccus;
    const deadbandScale: SimpleChange = changes.deadbandScale;
    const zones: SimpleChange = changes.zones;
    const siteId: SimpleChange = changes.siteId;
    if (changes.highlightCCU) {
      this.heatmapService.scrollToHighlightedElement(this.highlightCCU);
    }


    this.updateCCUs(ccus);

   this.updateFloors(floors);
  
    this.updateZones(zones);
    if (deadbandScale) {
      this.deadbandScale = deadbandScale.currentValue;
    }
    if (siteId && siteId.currentValue) {
      this.getScreenOrientation(this.siteId);
      this.getTags(siteId.currentValue);
    }
    this.storePrevDropDownValues();
  }

  updateCCUs(ccus) {
    if (ccus) {
    
      this.ccus = ccus.currentValue;

      let res = [];
      if(ccus.currentValue.length && ccus.previousValue.length &&(ccus.currentValue.length  == ccus.previousValue.length)) {
        ccus.currentValue.forEach(x => ccus.previousValue.forEach(y => {
          if(x._id == y._id) {
            res.push(x)
          }
        }))
      }

      this.ccusOptions = [{
        _id: 'all',
        name: 'All CCUs'
      }].concat(ccus.currentValue);
      this.selectedCCU = this.ccusOptions.map(x => x._id);
      if(this.ccuRef) {
        this.ccuRef['selectedValues'] = this.ccusOptions.filter(x => x);
      }

      if (this.floorSelectionExist)
        this.resetCCU()

        this.setExpandIcon();
    } else {
      this.noFloorSelected = true
   }
  }

  updateFloors(floors) {
    if (floors) {
      this.floors = [{
        _id: 'all',
        name: 'All Floors'
      }].concat(sortBy(floors.currentValue, [floor => floor.name.toLowerCase()], ['asc']));
      this.selectedFloor = this.floors.map(x => x._id);
      this.compareFloorChanges = ObjectUtil.deepClone(this.floors);
      if(this.floorRef) {
        this.floorRef['selectedValues'] = this.floors.filter(x => x);
      }
    }
  }

  updateZones(zones) {
    if (zones) {
      this.zones = zones.currentValue;
      this.filteredOptions = [{_id:'all',name:'All Zones'}].concat(zones.currentValue);
      this.selectedZones = this.filteredOptions.map(x =>x._id);
      if(this.zoneRef) {
        this.zoneRef['selectedValues'] = this.filteredOptions.filter(x =>x);
      }
    } else {
      this.noFloorSelected = true
    }
  }

  setExpandIcon() {
    setTimeout(()=>{
      let signlerow = document.getElementById('ccu-list')?.getBoundingClientRect()?.height < 60;
      if(signlerow) {
        this.hideCcuHeaderIcon = false
        this.expandCcu = true
      } else {
        this.expandCcu = false
        this.hideCcuHeaderIcon = true
      }
    })
  }
/**
 * It assigns the value for this.tagList
 */
  getTags(siteId) {
    this.heatmapSrvc.getSiteTags(siteId)
      .subscribe(({ rows }) => {
        this.tagList = uniq(rows[0].tags);
      });
  }

  ngOnInit() {
    this.siteName = JSON.parse(localStorage.getItem('SelectedSite')).siteName;
    this.hideQRCode = this.authService.certificationLevel == 'L0';
    this.subscriptions['globalDashboardStatus'] = this.heatmapConnect.getGlobalDashboardStatus().subscribe(statusObj => {
      // Show the various actions on the Draft management toolbar
      this.dFTStatusObject.showDFT = statusObj.showDFT;
      this.dFTStatusObject.published = statusObj.published;

      if (statusObj && statusObj.updateDateTime) {
        this.dFTStatusObject.updateDateTime =
        dayjs.utc(statusObj.updateDateTime).local().format('MMM D, YYYY | HH:mm');
      }
    });

    this.subscriptions['globalDashboardStatus'] = this.heatmapConnect.getHeatmapCustomChanges().subscribe(changesCount => {
      if (changesCount > 0) {
        this.dFTStatusObject.showDFT = true;
        this.dFTStatusObject.changesCount = changesCount;
      }
      // Also modifies the Draft management toolbar
    });

    this.filterZones();

    this.autoRefreshChange.emit(this.autoRefreshSelection);
    this.checkSelectedFilter();
    const container = document.getElementById("ccu-list");
    container.addEventListener("wheel", function(e) {
      e.preventDefault();
      container.scrollLeft += e.deltaX;
      let [x, y] = [e.deltaX, e.deltaY];
      let magnitude;

      if (x === 0) {
        magnitude = y > 0 ? -30 : 30;
      } else {
        magnitude = x;
      }
      container.scrollBy({
        left: magnitude
      });
    });

    this.filterSrvc.deletedFilter.subscribe((filterId) => {
      if(filterId){
        this.getFilterById(filterId);
      }
    });
  }
  
  checkCertificationAccess(view) {
		return this.authService.isUserAllowedToCheck(view);
	}

  async fetchNotesLength(){
    const siteRef = JSON.parse(localStorage.getItem('SelectedSite')).siteId;
    const notesList = await this.notesService.fetchNotes(siteRef, "", ["pinned"], [], null);
    this.notesList = notesList.filter(note => note.pinned && !note.archived);
}

/**
 * It checks seleted filter and calls emitFilter()
 */
  checkSelectedFilter() {
    // Function to get list of filters
    this.filterSrvc.getHeatmapFilterList()
      .subscribe((res: any) => {
        const createdByMeList = res.createdByMeList;
        const sharedByOthersList = res.sharedByOthersList;
        this.filterSrvc.setFilterList([...createdByMeList, ...sharedByOthersList]);
        this.emitFilter();
      }, err => {
        this.filterSrvc.setFilterList([]);
        this.emitFilter();
      });

  }
/**
 * It  calls .emit on this.filterChange
 */
  emitFilter() {
    this.selectedFilter = this.filterSrvc.getFilterFromList(this.selectedFilter);
    this.applyFilter(this.selectedFilter);
    this.defaultFilter = this.filterSrvc.getFilterFromList(this.defaultFilter);
    this.selectedFilter = this.unitService.formatFilter(this.selectedFilter);
  }

  displayFn(val) {
    return val ? val.name : val;
  }
/**
 * This menthod filters the zones
 */
  filterZones() {
    this.selectedOption = '';
    this._filter(this.zonesSearch);
    this.onChangeZone.emit('');
    let zonesList = this.filteredOptions.map((zone) => {
      return zone._id
    })
    this.onSeachInputChange.emit({
      searchText: typeof this.zonesSearch != 'string' ? '' : this.zonesSearch.trim(),
      zones: this.zonesSearch ? zonesList : []
    })
  }
/**
 * It  calls next on searchTextChanged Subject
 */
  zonesSearchChanged(event) {
    this.searchTextChanged.next(event);
  }


  public _filter(value) {
    if (typeof value != 'string') {
      return;
    }
    const filterValue = value.trim().toLowerCase();
    if (filterValue.length)
      this.filteredOptions = this.zones.filter((option: any) => option.name.toLowerCase().includes(filterValue));
    else
      this.filteredOptions = this.zones;
  }
/**
 * It  calls .emit on this.onChangeZone
 */
handleOptionSelected(event) {
  const zoneDetails = [];
  let zones = [];
  let eventNotTriggered : boolean = true
  if(event.length == 0){
    eventNotTriggered = false;
  }else if (event.length !== this.compareZoneChanges.length) {
    eventNotTriggered = false;
  } else {
    for (let i = 0; i < event.length; i++) {
      if (this.compareZoneChanges.findIndex(z=>z._id == event[i]._id) == -1) {
        eventNotTriggered = false;
        break;
      }
    }
  }
  if(eventNotTriggered){
    return
  }
  this.compareZoneChanges = event.filter(x =>x)
  zones = this.zones;
  const siteZones = zones.filter(e => this.selectedZones.includes(e._id))
  let zoneSelected = (siteZones.length == this.zones.length) || (event.length == 1 && event[0]._id == 'all') ? false : true;
  this.setZonesDropDownValues(event);

  if (zoneSelected && event.length != 0) {
    this.zoneRef['selectedValues'].forEach(x => {
      if (x._id != 'all') {
        zoneDetails.push({ _id: x._id, name: x.name })
      }
    });
    this.onChangeZone.emit(zoneDetails)
  } else if ((event.length == 0 && this.selectedFloor.length == this.floors.length && this.ccusOptions.length == this.selectedCCU.length) || (event.length == 1 && event[0]._id == 'all') || ( this.ccusOptions.length == this.selectedCCU.length && (this.selectedZones.length == this.filteredOptions.length) && (this.selectedFloor.length == this.floors.length))) {
    setTimeout(() => {
      this.onChangeZone.emit('')
  },);
  }
  this.storePrevDropDownValues();
}

setZonesDropDownValues(value) {
  const allZones = [];
  const newValues = value.filter(x => x);
  if (value?.length) {
    value.forEach(x => {
      if (x._id == 'all') {
        allZones.push(x);
      }
    })
  }
  //CASE1: Unselect all floor when an option is unselected:
  if (allZones?.length) {
    this.selectedZones = newValues.map(x => x._id);
    this.zoneRef['selectedValues'] = newValues.filter(x => x);
  }

  //CASE2: Select all floors , if all the floors all unselected
  if (!this.selectedZones.length) {
    this.selectedZones = this.filteredOptions.map(x => x._id);
    this.zoneRef['selectedValues'] = this.filteredOptions.filter(x => x);
  }

  //CASE 3: Select all floors, when "All floors are selected"
  if (allZones?.length && value?.length == 1 || (!this.selectedZones.includes('all') && this.selectedZones?.length == value?.length)) {
    this.selectedZones = this.filteredOptions.map(x => x._id);
    this.zoneRef['selectedValues'] = this.filteredOptions.filter(x => x);
  }

  this.setZoneIndeterminateValue()

}

setZoneIndeterminateValue() {
  if (this.selectedZones?.length == this.filteredOptions?.length) {
    this.zoneRef['selectedValues'] = this.filteredOptions.filter(x => x);
  }
}
/**
 * This method returns if this.selectedOption || !(this.zonesSearch)
 */
  handleClose() {
    if (this.selectedOption || !(this.zonesSearch)) {
      return;
    }
    // Promise.resolve().then(() => this.trigger.openPanel());
  }

/**
 * It  calls resetZone and calls .emit on onChangeFloor
 */

getFloorId(event) {
  let eventNotTriggered : boolean = true
  if(event.length == 0){
    eventNotTriggered = false;
    this.floorRef['selectedValues'] = this.floors.filter(x => x);
  }
  if (event.length !== this.compareFloorChanges.length) {
    eventNotTriggered = false;
  } else {
    for (let i = 0; i < event.length; i++) {
      if (this.compareFloorChanges.findIndex((f)=>f._id == event[i]._id) == -1) {
        eventNotTriggered = false;
        break;
      }
    }
  }
  if(eventNotTriggered){
    this.floorSelectionExist = false
    return;
  }
  this.compareFloorChanges = event.filter(x =>x)
  this.floorSelectionExist = true;
  const siteFloors = this.floors.filter(e => this.selectedFloor.includes(e._id))
  this.floorsSelected = (siteFloors.length == this.floors.length) || (event.length == 1 && event[0]._id == 'all') ? false : true;
  this.allFloor = [];
  if (event?.length) {
    event.forEach(x => {
      if (x._id == 'all') {
        this.allFloor.push(x);
      }
    })
  }
  this.setFloorDropDown(event);
  this.checkForFloorSelection(eventNotTriggered,event)
}


setFloorDropDown(value) {
  //CASE1: Unselect all floor when an option is unselected:
  if (this.allFloor?.length && (this.floors?.length > this.selectedFloor?.length)) {
    const newValues = value.filter(x => x);
    this.selectedFloor = newValues.map(x => x._id);
    this.floorRef['selectedValues'] = newValues.filter(x => x);
  }

  //CASE2: Select all floors , if all the floors all unselected
  if (!this.selectedFloor?.length) {
    this.selectedFloor = this.floors.map(x => x._id);
    this.floorRef['selectedValues'] = this.floors.filter(x => x);
  }

  //CASE 3: Select all floors, when "All floors are selected"
  if ((this.allFloor.length && value.length == 1) || (!this.selectedFloor.includes('all') && this.selectedFloor.length == value.length)) {
    const newValues = this.floors.filter(x => x);
    this.selectedFloor = newValues.map(x => x._id);
    this.floorRef['selectedValues'] = newValues.filter(x => x);
  }

  if (this.selectedFloor?.length == this.floors?.length) {
    this.floorRef['selectedValues'] = this.floors.filter(x => x);
  }
}

checkForFloorSelection(eventNotTriggered,event) {
  if (this.floorsSelected && !eventNotTriggered && (this.selectedFloor.length != this.floors.length)) {
    const zoneDetails = [];
    event.forEach(x => {
      if (x._id != 'all') {
      zoneDetails.push(x);
      }
    })
    setTimeout(() => {
      this.onChangeFloor.emit(zoneDetails);
  });
  } else {

    if ((event.length == 1 && event[0]._id == 'all') || ((event.length == this.selectedFloor.length) && !eventNotTriggered) || ((event.length != this.selectedFloor.lenth) && !this.allFloor.length)) {
      
      this.ccuRef['selectedValues'] = this.ccusOptions.filter(x => x);
      this.zoneRef['selectedValues'] = this.filteredOptions.filter(x => x);
      setTimeout(() => {
        this.onChangeFloor.emit('');
        this.onChangeCCU.emit('');
        this.onChangeZone.emit('');
    });
    }
  }
}

storePrevDropDownValues(){
  this.compareZoneChanges = ObjectUtil.deepClone(this.zoneRef?.['selectedValues'] || [])
  this.compareCCUChanges = ObjectUtil.deepClone(this.ccuRef?.['selectedValues'] || [])
  this.compareFloorChanges = ObjectUtil.deepClone(this.floorRef?.['selectedValues'] || [])
}


/**
 * This method resets the zone
 */
resetZone(selectedCCUId?) {
  const ccuChanged = selectedCCUId;
  let newZones = [];
  newZones = this.zones;
  this.zonesSearch = '';
  this.selectedOption = this.zonesSearch = '';
  this._filter(this.zonesSearch);

  //CASE A: zone option list according to floors selected
  if (this.selectedFloor?.length && !ccuChanged) {
    this.loadZonesBasedonFloorsOnly(newZones);
  }

  //CASE B: If the floors and CCU options are selected
  if (this.selectedFloor?.length && selectedCCUId?.length) {
    this.setZonesBasedonCcu(newZones)
  }

}

  loadZonesBasedonFloorsOnly(newZones) {
    const filteredOptions = [];
    newZones.forEach(x => this.selectedFloor.forEach(y => {
      if (x.referenceIDs.floor == y) {
        filteredOptions.push(x)
      }
    }))
    this.filteredOptions = [];
    this.zoneRef['selectedValues'] = [];
    this.selectedZones = [];
    this.filteredOptions = [{ _id: 'all', name: 'All Zones' }].concat(ArrayUtil.clone(filteredOptions));
    this.selectedZones = this.filteredOptions.map(x => x._id);
    this.zoneRef['selectedValues'] = this.filteredOptions.filter(x => x)
  }

  setZonesBasedonCcu(newZones) {
    const filteredOptions = [];
    const selectedCcuOptions = [];
    let ccus = [];
    ccus = this.ccus;

    //1.Get the zone details of selected ccu id(s)
    this.ccusWithPairedZones.forEach(x => this.selectedCCU.forEach(y => {
      if (x.id == y) {
        selectedCcuOptions.push(x)
      }
    }))

    //2.Add the ccuId for each zone corresponding to selected floor.(This can be made a common method)
    newZones.forEach((zone) => {
      let ccu = this.ccusWithPairedZones.find(ccu => {
        if (ccu.pairedZoneList.indexOf(zone._id) > -1) {
          return ccu;
        }
      });
      if (ccu) {
        zone['ccuId'] = ccu.id;
      }
    })

    //3.Filter out the required zone and set the values for dropdown
    newZones.forEach(x => selectedCcuOptions.forEach(y => {
      if (x.ccuId == y.id) {
        filteredOptions.push(x)
      }
    }))

    this.setZonesBasedonCCUselection(ccus, filteredOptions);
  }

  setZonesBasedonCCUselection(ccus, filteredOptions) {
    //4.Check the selected ccu values and pass it to heatmap-component and also set the zone details .
    this.ccusOptionsSelected = [];
    ccus.forEach(x => filteredOptions.forEach(y => {
      if (y.ccuId == x._id) {
        this.ccusOptionsSelected.push(y);
      }
    }))
    this.filteredOptions = [];

    //removing duplicates:
    const ids = this.ccusOptionsSelected.map(x => x._id)
    const filtered = this.ccusOptionsSelected.filter(({ id }, index) => !ids.includes(id, index + 1))
    let zonesBasedonCcuandFloor = [];
    filtered.forEach(x => {
      if (this.selectedFloor.includes(x.referenceIDs.floor)) {
        zonesBasedonCcuandFloor.push(x)
      }
    })

    this.filteredOptions = [{ _id: 'all', name: 'All Zones' }].concat(zonesBasedonCcuandFloor);
    this.zoneRef['selectedValues'] = this.filteredOptions.filter(x => x);
    let zoneDetails = [];
    this.zoneRef['selectedValues'].forEach(x => {
      if (x._id != 'all') {
        zoneDetails.push({ _id: x._id, name: x.name })
      }
    });
    this.selectedZones = this.filteredOptions.map(x => x._id);
    this.compareZoneChanges = ObjectUtil.deepClone(this.zoneRef['selectedValues'])
  }


/**
 * It  calls resetZone and calls .emit on onChangeCCU
 */
resetCCU() {
  this.resetZone(false);
  const highlightCcus = [];

  this.ccuRef['selectedValues'].forEach(x =>
    highlightCcus.push({ name: x.name, ccuId: x._id })
  )

  if (this.selectedFloor.length != this.floors.length) {
    let zoneDetails = [];
    this.zoneRef['selectedValues'].forEach(x => {
      if (x._id != 'all') {
        zoneDetails.push({ _id: x._id, name: x.name })
      }
    });

    setTimeout(() => {
      this.onChangeZone.emit(zoneDetails)
  });
    

  } else if (this.selectedFloor.length == this.floors.length) {
    this.ccuRef['selectedValues'] = this.ccusOptions.filter(x => x);
    this.zoneRef['selectedValues'] = this.filteredOptions.filter(x => x);
  }
  this.storePrevDropDownValues();
}

/**
 * It  calls resetZone and calls .emit on onChangeCCU
 */
changeCCUselection(event) {
  let eventNotTriggered : boolean = true
  if(event.length == 0){
    eventNotTriggered = false;
    this.ccuRef['selectedValues'] = this.ccusOptions.filter(x => x);
  } 
  if (event.length !== this.compareCCUChanges.length) {
    eventNotTriggered = false;
  } else {
    for (let i = 0; i < event.length; i++) {
      if (this.compareCCUChanges.findIndex(c=>c._id == event[i]._id) == -1) {
        eventNotTriggered = false;
        break;
      }
    }
  }

  if(eventNotTriggered){
    return
  }
  this.compareCCUChanges = event.filter(x =>x)
  let ccus = [];
  ccus = this.ccus;
  let siteCCU = ccus.filter(e => this.selectedCCU.includes(e._id))
  let siteFloor = this.floors.filter(e => this.selectedFloor.includes(e._id))
  this.ccuSelected = (siteCCU.length == this.ccus.length) || (event.length == 1 && event[0]._id == 'all') ? false : true;
  let floorSelected = (siteFloor.length == this.floors.length) ? false : true;
  let allCcu: boolean = false;
  event.forEach(x => {
    if (x._id.includes('all')) {
      allCcu = true
    }
  })
  this.setCCUDropDownValues(event, allCcu);
  const selectedCCU = [];
  this.ccuRef['selectedValues'].forEach(x => {
    if (x._id != 'all') {
      selectedCCU.push({ name: x.name, ccuId: x._id })
    }
  });

  this.setCCUValues(floorSelected,event,selectedCCU, eventNotTriggered);
  this.storePrevDropDownValues();
}

setCCUValues(floorSelected,event,selectedCCU, eventNotTriggered) {
  if ((!this.ccuSelected && !floorSelected) || (!this.selectedCCU.includes('all') && this.selectedCCU?.length == event.length) || (event.length == 0 && !floorSelected)) {
    setTimeout(() => {
      this.onChangeCCU.emit('');
      this.onChangeZone.emit('');
  });
    this.filteredOptions = [{ _id: 'all', name: 'All Zones' }].concat(this.zones);
    const zoneids = this.filteredOptions.map(x => x._id)
    const filteredZones = this.filteredOptions.filter(({ id }, index) => !zoneids.includes(id, index + 1))
    this.filteredOptions = filteredZones.filter(x => x);
    this.selectedZones = this.filteredOptions.map(x => x._id);
    this.zoneRef['selectedValues'] = this.filteredOptions.filter(x => x);
    this.selectedZones = this.filteredOptions.map(x => x._id);
  } else if (this.ccuSelected || (!this.ccuSelected && floorSelected)) {
    if(!eventNotTriggered) {
    this.resetZone(selectedCCU);
    const res = this.filteredOptions.filter((obj, index) => {
      return index === this.filteredOptions.findIndex(o => obj._id === o._id);
    })
    this.filteredOptions = res.filter(x => x);
    this.zoneRef['selectedValues'] = this.filteredOptions.filter(x => x);
    this.selectedZones = this.filteredOptions.map(x => x._id);

    let zoneDetails = [];
    this.zoneRef['selectedValues'].forEach(x => {
      if (x._id != 'all') {
        zoneDetails.push({ _id: x._id, name: x.name })
      }
    });
    setTimeout(() => {
      this.onChangeZone.emit(zoneDetails)
    });
    }
  }
  this.storePrevDropDownValues();
}

setCCUDropDownValues(value, allCcu) {
  //CASE1: Unselect all floor when an option is unselected:
  if ((allCcu && (this.ccusOptions?.length > this.selectedCCU?.length))) {
    const newValues = value.filter(x => x);
    this.selectedCCU = newValues.map(x => x._id);
    this.ccuRef['selectedValues'] = newValues.filter(x => x);
  }

  //CASE2: Select all floors , if all the floors all unselected
  if (!this.selectedCCU?.length) {
    this.selectedCCU = this.ccusOptions.map(x => x._id);
    this.ccuRef['selectedValues'] = this.ccusOptions.filter(x => x);
  }

  //CASE 3: Select all floors, when "All floors are selected"
  if ((allCcu && value?.length == 1) || (!this.selectedCCU.includes('all') && this.selectedCCU?.length == value.length)) {
    const newValues = this.ccusOptions.filter(x => x);
    this.selectedCCU = newValues.map(x => x._id);
    this.ccuRef['selectedValues'] = newValues.filter(x => x);
  }

  if ((this.selectedCCU?.length == this.ccusOptions?.length)) {
    this.ccuRef['selectedValues'] = this.ccusOptions.filter(x => x);
  }

}
/**
 * It   calls .emit on onHoverCcu
 */
  onCcuHover(ccuId, $event) {
    this.onHoverCcu.emit({ ccuId,  $event });
  }
/**
 * It   calls .emit on onLeaveCcu
 */
  onCcuLeave($event: any) {
    let ccuId = (<HTMLElement>event.target).id;
    this.onLeaveCcu.emit($event, ccuId);
  }

/**
 * It   calls .emit on onClickCcu
 */
  ccuSettings(ccuId,$event) {
    this.onClickCcu.emit({ ccuId, $event });
  }
/**
 * It   calls .emit on autoRefreshChange
 */
  setAutoRefresh(val) {
    if (val != 1) {
      this.autoRefreshSelection = val;
    }
    this.autoRefreshChange.emit(val);
  }
/**
 *This method sorts CCU and calls alert service
 */
  sortCCU() {
    const dialogRef: any = this.dialog.open(SortOrderComponent, {
      width: '400px',
      panelClass: 'sort-order-dialog',
      data: {
        list: ObjectUtil.deepClone(this.ccus),
        key: 'name',
        title: 'CCU\'s',
        subTitle: 'Drag and drop ccus to reorder'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userPref.updateUserPerf(this.siteId, {
          ccuIdsByInterest: result.map((ccu) => ccu._id)
        }).subscribe(res => {
          this.alertService.success("User preferences updated successfully");
          //this.ccus = result;
          this.ccuOrderChange.emit(res['ccuIdsByInterest'] || []);
        }, err => {
          this.alertService.error(err.error.msg)
        })

      }
    })
  }
/**
 * This method calls filterSrvc.closepopups.next
 */
  closeAllpopups(event) {
    this.filterSrvc.closepopups.next(false);
  }
/**
 * It   calls .emit on filterChange
 */
  applyFilter(filter, filterUpdated = false) {
    const selectedFilter = this.unitService.formatFilter(filter);
    if(this.selectedFilter?.filterId != selectedFilter?.filterId || filterUpdated) { 
      localStorage.setItem('selectedFilter',JSON.stringify(filter));
      this.selectedFilter = ObjectUtil.deepClone(selectedFilter);
      this.filterChange.emit(this.selectedFilter);
    }
  }
  //Called whenever default filter is applied
  selectDefaultFilter(filter){
    const defaultFilter = this.unitService.formatFilter(filter);
    this.defaultFilter = ObjectUtil.deepClone(defaultFilter);
    this.defaultFilterChange.emit(this.defaultFilter);
  }
/**
 * It   calls .emit on filterChange
 */
  clearFilteredData(event) {
    if (event) {
      localStorage.removeItem('selectedFilter');
      this.selectedFilter = this.defaultFilter;
      this.filterChange.emit(this.selectedFilter);
    }
  }
  getFilterById(filterId: string) {
    this.filterSrvc.getFilterByids(filterId).subscribe((filters: any) => {
      let updatedFilter = {};
      if (!filters.createdByMeList.length && !filters.sharedByOthersList.length) {
        if(this.defaultFilter?.filterId == filterId){
          updatedFilter = {filterId:'default',defaultInd:true};
          this.selectDefaultFilter(updatedFilter);
        }
        else{
          updatedFilter = this.defaultFilter;
        }
      } else if (filters.createdByMeList.length) {
        updatedFilter = filters.createdByMeList[0];
      } else if (filters.sharedByOthersList.length) {
        updatedFilter = filters.sharedByOthersList[0];
      }
      if(this.selectedFilter?.filterId == filterId)
        this.applyFilter(updatedFilter,true);
    });
  }
/**
 * This method assigns the value for showOrientationCompass
 */
  getScreenOrientation(siteRef: any) {
    this.siteService.getSiteFloors(siteRef).subscribe((res) => {
      if (res) {
        let data = res.rows
        let orientationsMapping = data.filter(x => x.orientation)
        if (orientationsMapping.length > 0) {
          this.orientationangle = this.hsHelper.stripHaystackTypeMapping(data[0].orientation).split(' ')[0];
          this.showOrientationCompass = true;
        } else {
          this.showOrientationCompass = false
        }
      }
    }, err => {

    })
  }
  ccuListHeader(expandCcu) {
    this.previousVal = expandCcu
    this.expandCcu = !this.expandCcu;
  }

  ccuId(index, ccu) {
    return ccu._id
  }

  // Deleting the exsting draft analytics
  deleteDraft() {
    this.heatmapConnect.setDraftActionTaken('delete');
  }

  // Publishing the exsting draft analytics
  publishDraft() {
    this.heatmapConnect.setDraftActionTaken('publish');
  }

  // Editing the existing analytics.
  editAnalytics() {
    this.heatmapConnect.setDraftActionTaken('editAnalytics');
  }

  get isCustomGlobalWidgetsEnabled(): boolean {
    return this.authService.isUserAllowedToCheck('customGlobalWidgets');
  }

  openPinnedNotes(){
    let emitRandomValue = Math.floor(Math.random() * 100) + 1;
    this.scrollAndExpandEvent.emit(emitRandomValue);
  }
  
  autoRefreshTooltipStatus(autoRefreshSelection) {
    return this.heatmapService.autoRefreshTooltipStatus(autoRefreshSelection);
  }

   //Method for triggering the pop up for QR Code List
   openQRCodeList() {
    this.heatmapService.openQRCodeList();
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach(e => {
      this.subscriptions[e].unsubscribe();
    });

    window.removeEventListener('storage', this.handleStorageEvent);
  }
}


